import React from 'react';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import {I_SeedsBag}    from '../../services/I_SeedsBag';
import S_App           from '../../services/S_App';

import './SeedsBag.scss';

type SeedsBagProps = {
  seedsBag: I_SeedsBag;
  onClick?: any;
}

export default function SeedsBag(props:SeedsBagProps) {
  let {seedsBag, onClick} = props;

  let expirationFormatted = seedsBag.expirationdate ? (new Date(seedsBag.expirationdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: undefined, weekday: undefined }) : undefined;

  return (
    <div className={'seeds-bag '+(seedsBag.isArchived?'isArchived ':'')+(seedsBag.isEmpty?'isEmpty ':'')}
      onClick={(e) => onClick(e,seedsBag)}>

      <Grid container>
        <Grid item xs={6} className="text-left">
          {seedsBag.harvestdate ? (
            <span className="date">Récolte : {S_App.formatDateInString(seedsBag.harvestdate)} </span>
          ):null}
        </Grid>
        <Grid item xs={6} className="text-right">
          {seedsBag.expirationdate ? (
            <span className="date">Expire : {expirationFormatted} </span>
          ):null}
        </Grid>
      </Grid>

      
      

      <div className="flags">
        { seedsBag.isArchived ? <Chip label="Archivé" size="small" className="flag flag-archived" /> : null }
        { seedsBag.isEmpty ? <Chip label="Vide" size="small" className="flag flag-empty" /> : null }
      </div>
    </div>
  );
};
