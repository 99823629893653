import React from 'react';

import Grid from '@material-ui/core/Grid';

import { I_SeedsBag } from '../../services/I_SeedsBag';

import SeedsBag   from '../elements/SeedsBag';

import './SeedsBagsGrid.scss';


type SeedsBagsGridProps = {
  seedsBags: I_SeedsBag[];
  onClickSeedsBag?: any;
}

export default function SeedsBagsGrid(props:SeedsBagsGridProps) {
  let {seedsBags, onClickSeedsBag} = props;

  if(!seedsBags || seedsBags.length===0)
    return <div className="empty-message">Aucun sachet de graines</div>;

  return (
    <Grid container className="seedsbags" justify="center">
      {seedsBags.map((seedsBag, index) => {
        return (
          <Grid item xs={12} key={index}>
            <SeedsBag seedsBag={seedsBag} onClick={onClickSeedsBag} />
          </Grid>
        );
      })}
    </Grid>
  );
};