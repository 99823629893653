import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Modal  from '@material-ui/core/Modal';
import Grid   from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import S_App         from   '../../services/S_App';
import './Modal.scss';



export default function ModalHelp(props) {
  let {isModalOpened,closeModal,contents} = props;
  const Element = contents;

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents">

        {Element}

      </div>
    </Modal>
  );
  
};