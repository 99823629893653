import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// import './Note.scss';

function Note(props) {
  const [editOn, setEditOn] = React.useState(false);

  if(!props.note)
    return null;

  var currentYear = (new Date()).getFullYear();

  var years = [];
  for (var i = 2013; i <= currentYear + 2; i++) {
    years.push(i);
  }

  let dateFormatted = props.note.lastupdate ? (new Date(props.note.lastupdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }) : null;
  
  return (
    <Paper elevation={1} className="paper-note">
      <TextField label={dateFormatted} variant="outlined" multiline rows="5" className="textfield"
        value={props.note.content} disabled={editOn===undefined || !editOn}
        onChange={(e) => props.changeNote(props.note,e.target.value)}
        onBlur={(e) => setEditOn(false)}
        onClick={editOn===undefined || !editOn ? (e) => setEditOn(true) : undefined }/>
      { editOn ? (
      <Select label="Année" variant="outlined" type="number" className="selectfield"
        value={props.note.year} disabled={editOn===undefined || !editOn}
        onChange={(e) => props.changeNoteYear(props.note,e.target.value)}>
        { years.map((y,index) => <MenuItem key={index} value={y}>{y}</MenuItem>) }
        </Select>
      ) : null }
      { editOn ? <Fab color={'default'} size="small" className="remove-notes-btn" onClick={(e) => props.removeNote(e,props.note)}><DeleteForeverIcon /></Fab> : null }
    </Paper>
  );
}
  
Note.propTypes = {
  note: PropTypes.any,

  removeNote: PropTypes.func,
  changeNote: PropTypes.func,
  changeNoteYear: PropTypes.func
};

export default Note;
