import React, { useState} from 'react';
import PropTypes from 'prop-types';

import Button     from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';

import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/Help';




import S_App from '../../services/S_App';

// import './InventoriesPage.scss';


export default function InventoriesPage(props) {
  let {openedInventory,inventories,closeInventory,removeOpenedInventory,removeInventory,checkInventoryItem,categories,varieties,bags} = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);



  const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  let openedCreatedateFormatted = openedInventory && openedInventory.createdate ? (new Date(openedInventory.createdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }) : null;
  let openedLastupdateFormatted = openedInventory && openedInventory.lastupdate ? (new Date(openedInventory.lastupdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }) : null;
  


  return (
    <div>

      { openedInventory ? (
      <div className="inventory-opened">
        <h3>Inventaire en cours</h3>
        <div className="dates">
          <span>Ouvert le {openedCreatedateFormatted}</span>
          { openedInventory.lastupdate!==openedInventory.createdate ? <span>Modifié le {openedLastupdateFormatted}</span> : null }
        </div>

        <div className="inventory-table">
          {categories.map((category,index) => {
            let families = category.families;
            return (
              <>
              {families.map((family, index2) => {
                let family_varieties = category && family && varieties ? varieties.filter(variety => variety.familyID === family.id && variety.categoryID === category.id) : [];
                return (
                  <div className="inventory-family" key={index2}>
                    <div className="reset-float">
                      <div className="float-right"><AddIcon /></div>
                      <strong>{family.label.fr}</strong>
                    </div>

                    {family_varieties.map((variety, index3) => {
                      let seedsBags = variety && bags ? bags.filter(bag => bag.varietyID === variety.id) : [];

                      if(!seedsBags || seedsBags.length===0)
                        return (
                          <div key={index3}>
                            <Grid container>
                              <Grid item xs={4} className="empty">{variety.name}</Grid>
                              <Grid item xs={6} className="empty">-</Grid>
                              <Grid item xs={2} className="text-right"></Grid>
                            </Grid>
                          </div>
                        );

                      return (
                        <div key={index3}>
                          {seedsBags.map((bag,index4) => {
                            let harvestdateFormatted = bag && bag.harvestdate ? (new Date(bag.harvestdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', weekday: undefined, hour: undefined, minute: undefined }) : null;
                            let isChecked = openedInventory.content ? S_App.getByID(openedInventory.content,bag.id) !== undefined : undefined;

                            if(bag.isArchived)
                              return null;

                            if(!bag.isEmpty)
                              return (
                                <Grid container key={index4}>
                                  <Grid item xs={4}>{variety.name}</Grid>
                                  <Grid item xs={6}>Sachet du {harvestdateFormatted}</Grid>
                                  <Grid item xs={2} className="text-right">
                                    { !isChecked ? <HelpOutlineIcon color="secondary" onClick={(e) => checkInventoryItem(e,bag)}/> : <DoneIcon className="green" /> }
                                  </Grid>
                                </Grid>
                              );

                            return (
                              <Grid container key={index4}>
                                <Grid item xs={4}>{variety.name}</Grid>
                                <Grid item xs={6} className="empty">Sachet du {harvestdateFormatted}</Grid>
                                <Grid item xs={2} className="text-right"></Grid>
                              </Grid>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              </>
            );
          })}
          
        </div>

        <Button variant="contained" color="primary" fullWidth={true} onClick={closeInventory}>Clore l'inventaire</Button>
        <Button variant="contained" fullWidth={true} onClick={removeOpenedInventory}>Annuler l'inventaire</Button>
      </div>
      ) : null }

      <div className="inventory-history">
        <h3>Inventaires précédents</h3>

        { inventories.map((inventory,index) => {

          let createdateFormatted = inventory.createdate ? (new Date(inventory.createdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }) : null;
          let closedateFormatted = inventory.closedate ? (new Date(inventory.closedate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }) : null;

          return (
            <Accordion key={index} expanded={expanded === 'panel'+index} onChange={handleChangeAccordion('panel'+index)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <div className="accordion-family">Clos le {closedateFormatted}</div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="inventory-table">
                  {categories.map((category,index) => {
                    let families = category.families;
                    return (
                      <>
                      {families.map((family, index2) => {
                        return (
                          <div className="inventory-family" key={index2}>
                            <div className="">
                              <strong>{family.label.fr}</strong>
                            </div>

                            {inventory.content.map((item, index3) => {
                              let bagID = item.id;
                              let bag = S_App.getByID(bags,bagID);
                              let varietyID = bag ? bag.varietyID : undefined;
                              let variety = varietyID!==undefined ? S_App.getByID(varieties,varietyID) : undefined;
                              let categoryID = variety ? variety.categoryID : undefined;
                              let familyID = variety ? variety.familyID : undefined;

                              if(categoryID===undefined || categoryID!==category.id)
                                return null;

                              if(familyID===undefined || familyID!==family.id)
                                return null;

                              if(bag.isEmpty)
                                return null;

                              let harvestdateFormatted = bag && bag.harvestdate ? (new Date(bag.harvestdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'numeric', day: 'numeric', weekday: undefined, hour: undefined, minute: undefined }) : null;
                              
                              return (
                                <Grid container key={index3}>
                                  <Grid item xs={4}>{variety.name}</Grid>
                                  <Grid item xs={6}>Sachet du {harvestdateFormatted}</Grid>
                                  <Grid item xs={2} className="text-right">
                                    <DoneIcon className="green" />
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </div>
                        );
                      })}
                      </>
                    );
                   })}
                  <Button variant="contained" fullWidth={true} onClick={(e) => removeInventory(e,inventory)}>Supprimer l'inventaire</Button>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};
