import React from 'react';

import Grid from '@material-ui/core/Grid';

import { I_Category } from '../../services/I_Category';
import { I_Family } from '../../services/I_Family';

import Family   from '../elements/Family';

import './FamiliesGrid.scss';


type FamiliesGridProps = {
  categories: I_Category[];
  onClickFamily?: any;
}

export default function FamiliesGrid(props:FamiliesGridProps) {
  let {categories, onClickFamily} = props;

  if(!categories || categories.length===0)
    return <div className="empty-message">Aucune catégorie</div>;

  return (
    <>
    {categories.map((category,index) =>  {
      let category_families = category.families;
      return (
        <div className="categorie">
          <h2>{category.label.fr}</h2>
          <Grid container className="families" justify="center">
            {category_families.map((family, index2) => {
              return (
                <Grid item xs={6} sm={4} md={3} key={index2}>
                  <Family category={category} family={family} onClick={onClickFamily}/>
                </Grid>
              );
            })}
          </Grid>
        </div>
      );
    })}
    </>
  );
};