import S_App    from   '../services/S_App';
import {I_Inventory} from   '../services/I_Inventory';

let TEST_INVENTORIES = [];

let S_Inventories = {
  STORAGE_KEY_INVENTORIES : 'inventories',
  STORAGE_KEY_OPENED_INVENTORY : 'opened_inventory',

  inventories : [],
  openedInventory : undefined,

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Inventories.tsx -- fetch()...');
    var inventories = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Inventories.STORAGE_KEY_INVENTORIES];
    if(inventories){
      S_Inventories.inventories = JSON.parse(inventories);
    }else{
      S_Inventories.inventories = TEST_INVENTORIES;
    }

    var openedInventory = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Inventories.STORAGE_KEY_OPENED_INVENTORY];
    if(openedInventory){
      S_Inventories.openedInventory = JSON.parse(openedInventory);
    }else{
      S_Inventories.openedInventory = undefined;
    }
      
    if(S_App.isLogDebug) console.log('-- S_Inventories.tsx -- fetch() inventories',S_Inventories.inventories);
    if(S_App.isLogDebug) console.log('-- S_Inventories.tsx -- fetch() openedInventory',S_Inventories.openedInventory);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Inventories.STORAGE_KEY_INVENTORIES] = JSON.stringify(S_Inventories.inventories);
    if(S_Inventories.openedInventory)
      window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Inventories.STORAGE_KEY_OPENED_INVENTORY] = JSON.stringify(S_Inventories.openedInventory);
    else
      window.localStorage.removeItem(S_App.STORAGE_KEY_PREFIX + '_' + S_Inventories.STORAGE_KEY_OPENED_INVENTORY);
    if(S_App.isLogDebug) console.log('-- S_Inventories.tsx -- store() inventories',S_Inventories.inventories); 
    if(S_App.isLogDebug) console.log('-- S_Inventories.tsx -- store() openedInventory',S_Inventories.openedInventory); 
    if(cb)
      cb();
  },


  getInventory: (id:number|string) => {
    return S_App.getByID(S_Inventories.inventories,id);
  },

  addInventoryInYear(year,cb){
    // si deja un d'ouvert, on ne fait rien
    if(S_Inventories.openedInventory!==undefined)
      return;

    let dateNow = (new Date()).getTime();
    let newOne = {
      id: dateNow,
      lastupdate: dateNow,
      createdate: dateNow,
      closedate: undefined,
      content: [],
      year: year
    };
    S_Inventories.openedInventory = newOne;
    S_Inventories.store(() => {
      if(cb) cb();
    });
  },

  closeOpenedInventory(cb){
    if(!S_Inventories.openedInventory)
      return;

    let dateNow = (new Date()).getTime();
    S_Inventories.openedInventory.closedate = dateNow;

    if(!S_Inventories.inventories)
      S_Inventories.inventories = [];
    S_Inventories.inventories.push(S_Inventories.openedInventory);
    if(S_App.isLogDebug) console.log('-- S_Inventories.tsx -- closeOpenedInventory() closed',S_Inventories.openedInventory);
    S_Inventories.openedInventory = undefined;
    S_Inventories.store(() => {
      if(cb) cb();
    });
  },


  checkOpenedInventoryItem(bag,cb){
    if(!S_Inventories.openedInventory)
      return;

    let dateNow = (new Date()).getTime();
    let newItem = {
      id: bag.id,
      checkdate: dateNow
    };
    S_Inventories.openedInventory.content.push(newItem);
    if(S_App.isLogDebug) console.log('-- S_Inventories.tsx -- checkOpenedInventoryItem() opened',S_Inventories.openedInventory);
    S_Inventories.store(() => {
      if(cb) cb();
    });
  },


  removeOpenedInventory(cb){
    if(!S_Inventories.openedInventory){
      if(cb) cb();
      return;
    }
    S_Inventories.openedInventory = undefined;
    S_Inventories.store(() => {
      if(cb) cb();
    });
  },
  removeInventory(inventory,cb){
    if(!S_Inventories.inventories || S_Inventories.inventories.length===0 || !inventory){
      if(cb) cb();
      return;
    }
    let foundNoteIndex = S_App.getIndexOfByID(S_Inventories.inventories,inventory.id);
    if(foundNoteIndex !== -1){
      S_Inventories.inventories.splice(foundNoteIndex,1);
    }
    S_Inventories.store(() => {
      if(cb) cb();
    });
  }

};
export default S_Inventories;