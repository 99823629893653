import React from 'react';
import PropTypes from 'prop-types';

import Modal  from '@material-ui/core/Modal';
import Grid   from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';

import Typography from '@material-ui/core/Typography';

import S_App         from   '../../services/S_App';
import S_Categories  from   '../../services/S_Categories';
import S_SeedsBags   from   '../../services/S_SeedsBags';
import './Modal.scss';

class ModalSeedsBag extends React.Component<any,any> {

  static propTypes = {
    isModalOpened: PropTypes.bool,
    closeModal: PropTypes.func,

    item: PropTypes.any,
    onClickDelete: PropTypes.func,
    onFieldChange: PropTypes.func
  };

  constructor(props: any) {
    super(props);
    this.state = {
      item: props.item,
      extend: false
    };
    this._initFunctions();
  }
  _initFunctions(){
    this.handleExtend = this.handleExtend.bind(this);
  }
  componentDidMount() {
    this.setState((state,props) => ({
      item: props.item
    }));
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.item !== this.props.item){
      this.setState((state,props) => ({
        item: props.item
      }));
    }
    if(prevProps.item===undefined && this.props.item!==undefined){
      this.setState((state,props) => ({
        extend: false
      }));
    }
  }

  handleExtend(e) {
    this.setState((state) => ({
      extend: !state.extend
    }));
  }



  render() {
    let {isModalOpened,closeModal,onClickDelete,onFieldChange} = this.props;
    let {item,extend} = this.state;

    // let itemAdv = S_Items.getItemAdv(this.state.item);

    // let typesArray = [].concat(S_Categories.types).sort((a, b) => {
    //   let orderA = a.order !== undefined ? a.order : a.id;
    //   let orderB = b.order !== undefined ? b.order : b.id;
    //   return orderB > orderA ? -1 : 1;
    // });

    return (
      <Modal
        open={isModalOpened}
        onClose={closeModal}>
        <div className="modal-contents">

          { item ? (
          <>

            <div className="modal-form">
              <form className="edition" noValidate autoComplete="off">


                <div className="fieldRow">
                  <TextField label="Date de récolte" variant="outlined" type="date" 
                    value={S_App.formatDateForMaterial(item.harvestdate)}
                    onChange={(e) => onFieldChange(e,'harvestdate',e.target.value)}/>
                </div>

                <div className="fieldRow">
                  <TextField label="Date d'expiration" variant="outlined" type="date" 
                    value={S_App.formatDateForMaterial(item.expirationdate)}
                    onChange={(e) => onFieldChange(e,'expirationdate',e.target.value)}/>
                </div>

                <div className="fieldRow">
                  <Button variant="contained" fullWidth={true} onClick={(e) => this.handleExtend(e)}>Autres paramètres</Button>
                  {extend ? (
                  <div>
                    autres...
                  </div>
                  ) : null}
                </div>

              </form>
            </div>

            <div className="modal-actions">
              <Grid container spacing={2} justify="center">
                <Grid item xs={6}>
                  <Button variant="contained" fullWidth={true} 
                    onClick={onClickDelete}>Supprimer</Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" fullWidth={true} 
                    onClick={closeModal}>Fermer</Button>
                </Grid>
              </Grid>
            </div>

          </>
          ) : null}

        </div>
      </Modal>
    );
  }
  
}

export default ModalSeedsBag;