import React from 'react';

// import Panels       from './components/Panels';
import Stocks       from './_seeds/Stocks';
import Dashboard    from './_seeds/Dashboard';
import YearsManager from './_seeds/YearsManager';
import { Panels } from 'react-mui-pwa-tools';

import S_Categories      from   './services/S_Categories';
import S_SeedsVarieties  from   './services/S_SeedsVarieties';
import S_SeedsBags       from   './services/S_SeedsBags';
import S_Notes           from   './services/S_Notes';
import S_Inventories     from   './services/S_Inventories';
import S_Harvests        from   './services/S_Harvests';

import './App.scss';

class App extends React.Component<any,any> {

  static propTypes = {};

  constructor(props: any) {
    super(props);
    this.state = {
      // donnees 
      seeds_categories: [],
      // seeds_families: [],
      seeds_varieties: [],
      seeds_bags: [],
      notes: [],
      inventories: [],
      openedInventory: undefined,
      harvests: [],

      // gestion du snackbar

      // gestion du Panels
      isPanelEnabled: true

    };
    this._initFunctions();
  }

  _initFunctions(){
    this.addNewBagOfVariety = this.addNewBagOfVariety.bind(this);
    this.deleteBag = this.deleteBag.bind(this);
    this.updateBagField = this.updateBagField.bind(this);

    this.addNewVariety = this.addNewVariety.bind(this);
    this.deleteVariety = this.deleteVariety.bind(this);
    this.updateVarietyField = this.updateVarietyField.bind(this);
    this.toggleVarietyMonth = this.toggleVarietyMonth.bind(this);
    this.deleteAllVarieties = this.deleteAllVarieties.bind(this);

    this.changeNote = this.changeNote.bind(this);
    this.changeNoteYear = this.changeNoteYear.bind(this);
    this.addNote = this.addNote.bind(this);
    this.addNoteInYear = this.addNoteInYear.bind(this);
    this.removeNote =this.removeNote.bind(this);

    this.addInventoryInYear = this.addInventoryInYear.bind(this);
    this.removeOpenedInventory = this.removeOpenedInventory.bind(this);
    this.removeInventory = this.removeInventory.bind(this);
    this.closeOpenedInventory = this.closeOpenedInventory.bind(this);
    this.checkInventoryItem = this.checkInventoryItem.bind(this);

    this.addHarvestInYear = this.addHarvestInYear.bind(this);
    this.updateHarvest = this.updateHarvest.bind(this);
    this.deleteHarvest = this.deleteHarvest.bind(this);
    this.deleteAllHarvests = this.deleteAllHarvests.bind(this);

    this.togglePanels = this.togglePanels.bind(this);
  }
  componentDidMount() {
    S_Categories.fetch(() => {
      this.setState({
        seeds_categories: S_Categories.seeds_categories,
        // seeds_families: S_Categories.seeds_families
      });
    });
    S_SeedsVarieties.fetch(() => {
      this.setState({
        seeds_varieties: S_SeedsVarieties.seeds_varieties
      });
    });
    S_SeedsBags.fetch(() => {
      this.setState({
        seeds_bags: S_SeedsBags.seeds_bags
      });
    });
    S_Notes.fetch(() => {
      this.setState({
        notes: S_Notes.notes
      });
    });
    S_Inventories.fetch(() => {
      this.setState({
        inventories: S_Inventories.inventories,
        openedInventory: S_Inventories.openedInventory
      });
    });
    S_Harvests.fetch(() => {
      this.setState({
        harvests: S_Harvests.harvests
      });
    });
  }



  togglePanels(){
    this.setState((state) => ({
      isPanelEnabled: !state.isPanelEnabled
    }));
  }

  // ---------------------------------------------------------------------------------
  //    SeedsBag
  // ---------------------------------------------------------------------------------

  addNewBagOfVariety(variety,cb) {
    S_SeedsBags.add(variety.id, (newOne) => {
      this.setState({
        seeds_bags: S_SeedsBags.seeds_bags
      });
      if(cb)
        cb(newOne);
    });
  }

  deleteBag(bag,cb){
    // console.log('--App-- deleteBag()',bag);
    S_SeedsBags.delete(bag, () => {
      this.setState({
        seeds_bags: S_SeedsBags.seeds_bags
      });
      if(cb)
        cb();
    });
  }

  updateBagField(bag,attribute,value,cb){
    S_SeedsBags.update(bag,attribute,value, () => {
      this.setState({
        seeds_bags: S_SeedsBags.seeds_bags
      });
      if(cb)
        cb();
    });
  }

  // ---------------------------------------------------------------------------------
  //    Variety
  // ---------------------------------------------------------------------------------

  addNewVariety(category,family,cb) {
    S_SeedsVarieties.add(category.id,family.id, (newOne) => {
      this.setState({
        seeds_varieties: S_SeedsVarieties.seeds_varieties
      });
      if(cb)
        cb(newOne);
    });
  }

  deleteVariety(variety,cb){
    // console.log('--App-- deleteBag()',bag);
    S_SeedsVarieties.delete(variety, () => {
      this.setState({
        seeds_varieties: S_SeedsVarieties.seeds_varieties
      });
      if(cb)
        cb();
    });
  }

  updateVarietyField(variety,attribute,value,cb){
    S_SeedsVarieties.update(variety,attribute,value, () => {
      this.setState({
        seeds_varieties: S_SeedsVarieties.seeds_varieties
      });
      if(cb)
        cb();
    });
  }

  toggleVarietyMonth(variety,attribute,month,cb){
    S_SeedsVarieties.toggleMonth(variety,attribute,month, () => {
      this.setState({
        seeds_varieties: S_SeedsVarieties.seeds_varieties
      });
      if(cb)
        cb();
    });
  }

  deleteAllVarieties(cb){
    S_SeedsVarieties.deleteAll(() => {
      this.setState({
        seeds_varieties: S_SeedsVarieties.seeds_varieties
      });
      if(!!cb)
        cb();
    });
  }

  // ---------------------------------------------------------------------------------
  //    Inventories
  // ---------------------------------------------------------------------------------

  addInventoryInYear(e,year){
    S_Inventories.addInventoryInYear(year,() => {
      this.setState((state) => ({
        inventories: S_Inventories.inventories,
        openedInventory: S_Inventories.openedInventory
      }));
    });
  }

  removeOpenedInventory(e){
    S_Inventories.removeOpenedInventory(() => {
      this.setState((state) => ({
        openedInventory: S_Inventories.openedInventory
      }));
    });
  }

  removeInventory(e,inventory){
    S_Inventories.removeInventory(inventory,() => {
      this.setState((state) => ({
        inventories: S_Inventories.inventories
      }));
    });
  }

  closeOpenedInventory(e){
    S_Inventories.closeOpenedInventory(() => {
      this.setState((state) => ({
        inventories: S_Inventories.inventories,
        openedInventory: S_Inventories.openedInventory
      }));
    });
  }

  checkInventoryItem(e,bag){
    S_Inventories.checkOpenedInventoryItem(bag,() => {
      this.setState((state) => ({
        inventories: S_Inventories.inventories,
        openedInventory: S_Inventories.openedInventory
      }));
    });
  }

  // ---------------------------------------------------------------------------------
  //    Harvests
  // ---------------------------------------------------------------------------------

  addHarvestInYear(e,year,datas){
    S_Harvests.addHarvestInYear(year,datas,() => {
      this.setState((state) => ({
        harvests: S_Harvests.harvests
      }));
    });
  }

  updateHarvest(e,harvest,datas){
    S_Harvests.updateHarvest(harvest,datas,() => {
      this.setState((state) => ({
        harvests: S_Harvests.harvests
      }));
    });
  }

  deleteHarvest(e,harvest){
    S_Harvests.deleteHarvest(harvest,() => {
      this.setState((state) => ({
        harvests: S_Harvests.harvests
      }));
    });
  }

  deleteAllHarvests(){
    S_Harvests.deleteAll(() => {
      this.setState({
        harvests: S_Harvests.harvests
      });
    });
  }


  // ---------------------------------------------------------------------------------
  //    Notes
  // ---------------------------------------------------------------------------------

  changeNote(note,value) {
    S_Notes.updateNote(value,note,(foundData) => {
      if(!foundData)
        return;
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }

  changeNoteYear(note,value) {
    S_Notes.updateNoteYear(value,note,(foundData) => {
      if(!foundData)
        return;
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }

  addNote(e){
    S_Notes.addNote(() => {
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }

  addNoteInYear(e,year){
    S_Notes.addNoteInYear(year,() => {
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }

  removeNote(e,note){
    S_Notes.removeNote(note,() => {
      this.setState((state) => ({
        notes: S_Notes.notes
      }));
    });
  }



  render() {

    let DATAS_TEST = [
      <Stocks
        categories={this.state.seeds_categories}
        varieties={this.state.seeds_varieties}
        addNewBagOfVariety={this.addNewBagOfVariety}
        deleteBag={this.deleteBag}
        updateBag={this.updateBagField}
        addNewVariety={this.addNewVariety}
        deleteVariety={this.deleteVariety}
        updateVariety={this.updateVarietyField}
        toggleVarietyMonth={this.toggleVarietyMonth}
        togglePanels={this.togglePanels}
         />,
      <Dashboard 
        deleteAllVarieties={this.deleteAllVarieties}
        deleteAllHarvests={this.deleteAllHarvests}/>,
      <YearsManager 
        categories={this.state.seeds_categories}
        varieties={this.state.seeds_varieties}
        bags={this.state.seeds_bags}

        openedInventory={this.state.openedInventory}
        inventories={this.state.inventories}
        addInventory={this.addInventoryInYear}
        removeOpenedInventory={this.removeOpenedInventory}
        removeInventory={this.removeInventory}
        closeInventory={this.closeOpenedInventory}
        checkInventoryItem={this.checkInventoryItem}

        harvests={this.state.harvests}
        addHarvestInYear={this.addHarvestInYear}
        updateHarvest={this.updateHarvest}
        deleteHarvest={this.deleteHarvest}

        notes={this.state.notes}
        addNote={this.addNoteInYear}
        removeNote={this.removeNote}
        changeNote={this.changeNote}
        changeNoteYear={this.changeNoteYear}/>
    ];

    let TABS = [
      {
        label: 'Catalogue'
      },
      {
        label: 'Dashboard'
      },
      {
        label: 'Années'
      }
    ];


    return (
      <Panels 
        enabled={this.state.isPanelEnabled}
        contents={DATAS_TEST}
        tabs={TABS}
        defaultPanelIndex={1}
        tabsLabelOnSmallScreen={true}/>
    );
  }
}

export default App;
