import React from 'react';

import Grid       from '@material-ui/core/Grid';
import Button     from '@material-ui/core/Button';

import AddIcon    from '@material-ui/icons/Add';

import {I_Variety}   from '../../services/I_Variety';
import S_SeedsBags   from '../../services/S_SeedsBags';

import Variety            from '../elements/Variety';
import SeedsBagsGrid      from '../components/SeedsBagsGrid';

import './VarietiesGrid.scss';


type VarietiesGridProps = {
  varieties: I_Variety[];
  onClickVariety?: any;
  onClickVarietyNewBag?: any;
  onClickBag?: any;
}

export default function VarietiesGrid(props:VarietiesGridProps) {
  let {varieties, onClickVariety, onClickVarietyNewBag, onClickBag} = props;

  if(!varieties || varieties.length===0)
    return <div className="empty-message">Aucune variété</div>;

  return (
    <Grid container className="varieties" justify="center">
      {varieties.map((variety, index) => {
        let seedsBags = S_SeedsBags.getSeedsBags(variety.id);
        return (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Variety
              variety={variety}
              onClick={(e) => onClickVariety(e,variety)}
              content={ <>
                          <SeedsBagsGrid
                            seedsBags={seedsBags}
                            onClickSeedsBag={onClickBag} />
                          <Button variant="contained" onClick={(e) => onClickVarietyNewBag(e,variety)}>
                            <AddIcon />Nouveau sachet de graines
                          </Button>
                        </>}  />
          </Grid>
        );
      })}
    </Grid>
  );
};