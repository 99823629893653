import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Button     from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import AddIcon          from '@material-ui/icons/Add';
import CloseIcon        from '@material-ui/icons/Close';

import { I_Category }   from '../services/I_Category';
import { I_Family }     from '../services/I_Family';
import { I_Variety }    from '../services/I_Variety';

import ModalSeedsBag      from   './modals/ModalSeedsBag';
import ModalSeedsVariety  from   './modals/ModalSeedsVariety';

import FamiliesGrid       from   './components/FamiliesGrid';
import VarietiesGrid      from   './components/VarietiesGrid';

import Family             from   './elements/Family';

import S_Categories       from   '../services/S_Categories';
import S_SeedsVarieties   from   '../services/S_SeedsVarieties';

import './Stocks.scss';

const FRAME_FAMILY = 'FAMILY';


type StocksProps = {
  categories: I_Category[];
  // families: I_Family[];
  varieties: I_Variety[];

  addNewBagOfVariety: any;
  deleteBag: any;
  updateBag: any;

  addNewVariety: any;
  deleteVariety: any;
  updateVariety: any;
  toggleVarietyMonth: any;

  togglePanels: any;
};

export default function Stocks(props:StocksProps) {
  const [frame, setFrame] = React.useState(undefined);        // gestion des frames
  const [category, setCategory] = React.useState(undefined);      // objets detailles
  const [family, setFamily] = React.useState(undefined);      // objets detailles
  const [isModalBagOpened, setIsModalBagOpened] = React.useState(false);
  const [editedBag, setEditedBag] = React.useState(undefined);
  const [isModalVarietyOpened, setIsModalVarietyOpened] = React.useState(false);
  const [editedVariety, setEditedVariety] = React.useState(undefined);


  // ---------------------------------------------------------------------------------
  //    Family
  // ---------------------------------------------------------------------------------

  function handleClickFamily(e,category,family){
    console.log('handleClickFamily',category,family);
    e.preventDefault();
    e.stopPropagation();
    setFrame(FRAME_FAMILY);
    setCategory(category);
    setFamily(family);
  };

  function handleCloseFamily(e){
    e.preventDefault();
    e.stopPropagation();
    setFrame(undefined);
    setCategory(undefined);
    setFamily(undefined);
  }

  // ---------------------------------------------------------------------------------
  //    SeedsBag
  // ---------------------------------------------------------------------------------

  function handleClickNewBagOfVariety(e,variety){
    e.preventDefault();
    e.stopPropagation();
    // create new
    props.addNewBagOfVariety(variety, (newOne) => {
      // open modal edit
      openModalBag(newOne);
    });
  }

  function handleClickEditBag(e,bag) {
    e.preventDefault();
    e.stopPropagation();
    // open modal edit
    openModalBag(bag);
  }

  function handleClickDeleteBag(e){
    e.preventDefault();
    e.stopPropagation();
    // delete
    props.deleteBag(editedBag,() => {
      // close modal
      closeModalBag();
    });
  }

  function handleChangeBagField(e,attribute,value) {
    e.preventDefault();
    e.stopPropagation();
    // update
    props.updateBag(editedBag,attribute,value,() => {
      // close modal
      //closeModalBag();
    });
  }


  // ---------------------------------------------------------------------------------
  //    Variety
  // ---------------------------------------------------------------------------------

  function handleClickNewVariety(e,category,family){
    e.preventDefault();
    e.stopPropagation();
    // create new
    props.addNewVariety(category,family,(newOne) => {
      // open modal edit
      openModalVariety(newOne);
    });
  }

  function handleClickEditVariety(e,variety) {
    e.preventDefault();
    e.stopPropagation();
    // open modal edit
    openModalVariety(variety);
  }

  function handleClickDeleteVariety(e){
    e.preventDefault();
    e.stopPropagation();
    // delete
    props.deleteVariety(editedVariety,() => {
      // close modal
      closeModalVariety();
    });
  }

  function handleChangeVarietyField(e,attribute,value) {
    e.preventDefault();
    e.stopPropagation();
    // update
    props.updateVariety(editedVariety,attribute,value,() => {
      // close modal
      //closeModalBag();
    });
  }

  function handleChangeVarietyMonth(e,variety,attribute,month) {
    e.preventDefault();
    e.stopPropagation();
    props.toggleVarietyMonth(editedVariety,attribute,month,() => {
      // close modal
      //closeModalBag();
    });
  }


  // ---------------------------------------------------------------------------------
  //    MODALS
  // ---------------------------------------------------------------------------------
  function openModalBag(bag){
    props.togglePanels();
    setIsModalBagOpened(true);
    setEditedBag(bag);
  }
  function closeModalBag(){
    props.togglePanels();
    setIsModalBagOpened(false);
    setEditedBag(undefined);
  }
  function openModalVariety(variety){
    props.togglePanels();
    setIsModalVarietyOpened(true);
    setEditedVariety(variety);
  }
  function closeModalVariety(){
    props.togglePanels();
    setIsModalVarietyOpened(false);
    setEditedVariety(undefined);
  }

  // ---------------------------------------------------------------------------------
  //    RENDER
  // ---------------------------------------------------------------------------------

  let {categories} = props;

  let family_varieties = undefined;
  if(family) 
    family_varieties = S_SeedsVarieties.getSeedsVarieties(category.id,family.id);


  // Liste des varietes d'une famille -------------------------

  if(frame === FRAME_FAMILY)
    return (
      <div className="stocks stocks--family">

        <Container maxWidth="lg">

          <h2>{ category ? category.label.fr : null } - { family ? family.label.fr : '???' }</h2>
          <h3><Button variant="contained" size="small" onClick={handleCloseFamily}><CloseIcon /></Button></h3>

          {family ? (
          <>
          <VarietiesGrid 
            varieties={family_varieties} 
            onClickVariety={handleClickEditVariety}
            onClickVarietyNewBag={handleClickNewBagOfVariety}
            onClickBag={handleClickEditBag}/>
          <Button variant="contained" onClick={(e) => handleClickNewVariety(e, category, family)}>
            <AddIcon />Nouvelle variété
          </Button>
          </>
          ) : (
          <div>Aucune famille sélectionnée</div>
          )}

        </Container>
        
        <ModalSeedsBag
          isModalOpened={isModalBagOpened}
          closeModal={closeModalBag}
          item={editedBag}
          onClickDelete={handleClickDeleteBag}
          onFieldChange={handleChangeBagField}/>
         <ModalSeedsVariety
          isModalOpened={isModalVarietyOpened}
          closeModal={closeModalVariety}
          item={editedVariety}
          onClickDelete={handleClickDeleteVariety}
          onFieldChange={handleChangeVarietyField}
          onMonthChange={handleChangeVarietyMonth}  />
      </div>
    );

  
  // Listes des familles ----------------------

  return (
    <div className="stocks stocks--general">

      <Container maxWidth="lg">

        <FamiliesGrid 
          categories={categories}
          onClickFamily={handleClickFamily} />

      </Container>

    </div>
  );
};
