import S_App    from   '../services/S_App';
import {DEFAULT_SEEDS_CATEGORIES}   from   '../services/CATEGORIES';
import { I_Category } from '../services/I_Category';

let S_Categories = {
  seeds_categories : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch()...');
    S_Categories.seeds_categories = DEFAULT_SEEDS_CATEGORIES;
      
    if(S_App.isLogDebug) console.log('-- S_Categories.tsx -- fetch() seeds_categories',S_Categories.seeds_categories);
    if(cb)
      cb();
  },
  store: (cb) => { 
    if(cb)
      cb();
  },

  getSeedsCategory: (id:number|string) => {
    return S_App.getByID(S_Categories.seeds_categories,id);
  },
  getSeedsFamily: (category:I_Category,id:number|string) => {
    console.log('getSeedsFamily',category,id,S_App.getByID(category.families,id));
    return S_App.getByID(category.families,id);
  }

};
export default S_Categories;