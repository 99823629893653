import React from 'react';
import PropTypes from 'prop-types';

import Note      from   '../elements/Note';

import './Notes.scss';

function Notes(props) {
  if(!props.notes)
    return null;

  return (
    <>
      { props.notes.map((note, index) => {
          if(!note)
            return null;

          // console.log(note);
          return (
            <Note key={index} 
              note={note}
              removeNote={props.removeNote}
              changeNote={props.changeNote}
              changeNoteYear={props.changeNoteYear} />
          );
        }
      ) }
    </>
  );
}
  
Notes.propTypes = {
  notes: PropTypes.any,

  removeNote: PropTypes.func,
  changeNote: PropTypes.func,
  changeNoteYear: PropTypes.func
};

export default Notes;
