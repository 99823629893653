import S_App    from   '../services/S_App';
import {DEFAULT_SEEDS_BAGS}   from   '../services/CATEGORIES';

let S_SeedsBags = {
  STORAGE_KEY_BAGS : 'bags',

  seeds_bags : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- fetch()...');
    var seeds_bags = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_SeedsBags.STORAGE_KEY_BAGS];
    if(seeds_bags){
      S_SeedsBags.seeds_bags = JSON.parse(seeds_bags);
    }else{
      S_SeedsBags.seeds_bags = DEFAULT_SEEDS_BAGS;
    }
      
    if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- fetch() seeds_bags',S_SeedsBags.seeds_bags);
    if(cb)
      cb();
  },
  store: (cb) => { 
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_SeedsBags.STORAGE_KEY_BAGS] = JSON.stringify(S_SeedsBags.seeds_bags);
    if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- store() seeds_bags',S_SeedsBags.seeds_bags); 
    if(cb)
      cb();
  },

  getSeedsBag: (id:number|string) => {
    return S_App.getByID(S_SeedsBags.seeds_bags,id);
  },
  getSeedsBags: (varietyID:number) => {
    return S_App.getByFilters(S_SeedsBags.seeds_bags,[{attribute:'varietyID',value:varietyID}]);
  },



  add: (varietyID, cb) => {
    if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- add()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      creationdate: currentDate,
      lastupdate: currentDate,

      parentID: undefined,       // creation sans parent
      varietyID: varietyID,

      harvestdate: currentDate
    };
    if(!S_SeedsBags.seeds_bags)
      S_SeedsBags.seeds_bags = [];
    S_SeedsBags.seeds_bags.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- add() added',newOne);
    S_SeedsBags.store(() => {
      if(cb) cb(newOne);
    });
  },
  delete(item,cb){
    if(!S_SeedsBags.seeds_bags || S_SeedsBags.seeds_bags.length===0 || !item){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- delete()',item,'...'); 
    let foundIndex = S_App.getIndexOfByID(S_SeedsBags.seeds_bags,item.id);
    if(foundIndex !== -1){
      S_SeedsBags.seeds_bags.splice(foundIndex,1);
      if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- delete() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_SeedsBags.tsx -- delete() not found'); 
    }
    S_SeedsBags.store(() => {
      if(cb) cb();
    });
  },
  update(item,attribute,value,cb){
    if(!S_SeedsBags.seeds_bags || S_SeedsBags.seeds_bags.length===0 || !item){
      if(cb) cb();
      return;
    }
     
    let currentDate = (new Date()).getTime();
    let foundData = undefined;
    for(var i = 0; i < S_SeedsBags.seeds_bags.length; i++){
      if(S_SeedsBags.seeds_bags[i].id !== item.id)
        continue;
      foundData = S_SeedsBags.seeds_bags[i];
      if('creationdate'===attribute || 'lastupdate'===attribute || 'expirationdate'===attribute || 'harvestdate'===attribute){
        foundData[attribute] = value!==undefined ? (new Date(value+'T12:00:00')).getTime() : undefined;
        foundData.lastupdate = currentDate;
      }else{
        foundData[attribute] = value;
        foundData.lastupdate = currentDate;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_SeedsBags.tsx -- update() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_SeedsBags.tsx -- update() updated',foundData);
    S_SeedsBags.store(() => {
      if(cb) cb(foundData);
    });
  }

};
export default S_SeedsBags;