import React from 'react';

import Chip from '@material-ui/core/Chip';

import LocalFloristIcon from '@material-ui/icons/LocalFlorist';

import {I_Variety}   from '../../services/I_Variety';
import S_SeedsBags   from '../../services/S_SeedsBags';

import './Variety.scss';

type VarietyProps = {
  variety: I_Variety;
  onClick?: any;
  content?: any;
}

export default function Variety(props:VarietyProps) {
  let {variety, onClick, content} = props;
  const Element = content;

  let seedsBags = S_SeedsBags.getSeedsBags(variety.id);

  let isEmpty = false;
  if(!seedsBags || seedsBags.length===0){
    isEmpty = true;
  }else{
    isEmpty = true;
    for(var i = 0; i < seedsBags.length; i++){
      if(!seedsBags[i].isEmpty){
        isEmpty = false;
        break;
      }
    }
  }
    

  return (
    <div className={'variety '+(isEmpty?'isEmpty ':'')}>

      <div className="variety-description" onClick={onClick}>
        <div className="icon">
          <LocalFloristIcon />
        </div>
        <span className="name">{variety.name}</span>

        <div className="months-indications">
          { !! variety.seedlingMonths && variety.seedlingMonths.length>0 ? (
          <div className="months-indication">
            <strong>Semis : </strong>
            {variety.seedlingMonths.map((month,index) => {
              let label = '';
              if(month===1) label = 'Jan.';
              if(month===2) label = 'Fév.';
              if(month===3) label = 'Mars';
              if(month===4) label = 'Avr.';
              if(month===5) label = 'Mai';
              if(month===6) label = 'Juin';
              if(month===7) label = 'Juil.';
              if(month===8) label = 'Août';
              if(month===9) label = 'Sep.';
              if(month===10) label = 'Oct.';
              if(month===11) label = 'Nov.';
              if(month===12) label = 'Déc.';
              return <span key={index}>{ index > 0 ? ', ' : ''}{label}</span>;
            })}
          </div>
          ) : null }

          { !! variety.harvestMonths && variety.harvestMonths.length>0 ? (
          <div className="months-indication">
            <strong>Récoltes : </strong>
            {variety.harvestMonths.map((month,index) => {
              let label = '';
              if(month===1) label = 'Jan.';
              if(month===2) label = 'Fév.';
              if(month===3) label = 'Mars';
              if(month===4) label = 'Avr.';
              if(month===5) label = 'Mai';
              if(month===6) label = 'Juin';
              if(month===7) label = 'Juil.';
              if(month===8) label = 'Août';
              if(month===9) label = 'Sep.';
              if(month===10) label = 'Oct.';
              if(month===11) label = 'Nov.';
              if(month===12) label = 'Déc.';
              return <span key={index}>{ index > 0 ? ', ' : ''}{label}</span>;
            })}
          </div>
          ) : null }
        </div>
      </div>

      {content ? (
        <div>
          {Element}
        </div>
      ):null}
      
      <div className="flags">
        { isEmpty ? <Chip label="Vide" variant="outlined" size="small" className="flag flag-empty" /> : null }
      </div>

    </div>
  );
};
