import React, { useState} from 'react';
import PropTypes from 'prop-types';

import Button     from '@material-ui/core/Button';

import EditIcon from '@material-ui/icons/Edit';

// import './StatsPage.scss';


export default function StatsPage(props) {
  let {} = props;

  return (
    <div>
      <div>Cet encart affichera :
      <br/>* les graphs météo : précipitations, températures, ensoleillement,
      <br/>* les graphs stats de récolte</div>
    </div>
  );
};
