import React from 'react';

import LocalFloristIcon from '@material-ui/icons/LocalFlorist';

import {I_Category}       from '../../services/I_Category';
import {I_Family}         from '../../services/I_Family';
import S_SeedsVarieties   from   '../../services/S_SeedsVarieties';

import './Family.scss';

type FamilyProps = {
  category: I_Category;
  family: I_Family;
  onClick?: any;
}

export default function Family(props:FamilyProps) {
  let {category,family, onClick} = props;

  if(!category || !family)
    return null;

  let varieties = S_SeedsVarieties.getSeedsVarieties(category.id,family.id);

  return (
    <div className={'family ' + (varieties.length > 0 ? 'with-varieties' : 'without-varieties')} 
      onClick={(e) => onClick(e,category,family)}>
      <div className="icon">
        <LocalFloristIcon />
      </div>
      <div className="name">{family.label.fr}</div>
      {varieties.length > 0 ? (
      <div className="varieties">
        <span>{varieties.length} variétés</span>
      </div>
      ) : null }
    </div>
  );
};
