import React from 'react';
import PropTypes from 'prop-types';

// import S_App         from   '../../services/S_App';

import S_SeedsVarieties   from   '../../services/S_SeedsVarieties';

import './Month.scss';

export default function Month(props) {
  let {month} = props;

  if(!month)
    return null;

  let name = undefined;
  if(month===1)  name = 'Janvier';
  if(month===2)  name = 'Février';
  if(month===3)  name = 'Mars';
  if(month===4)  name = 'Avril';
  if(month===5)  name = 'Mai';
  if(month===6)  name = 'Juin';
  if(month===7)  name = 'Juillet';
  if(month===8)  name = 'Août';
  if(month===9)  name = 'Septembre';
  if(month===10)  name = 'Octobre';
  if(month===11)  name = 'Novembre';
  if(month===12)  name = 'Décembre';

  let seedling = S_SeedsVarieties.getSeedsVarieties_bySeedlingMonth(month);
  let planting = S_SeedsVarieties.getSeedsVarieties_byPlantingMonth(month);
  let harvest = S_SeedsVarieties.getSeedsVarieties_byHarvestMonth(month);

  return (
    <div className="slider-by-month-slide month">

      <h3>{name}</h3>

      <div className="contents">

        { !!seedling && seedling.length>0 ? (
        <div>
          <h4>Semis</h4>
          { seedling.map((variety,index) => (
          <div key={index}>{variety.name}</div>
          )) }
        </div>
        ) : null }

        { !!planting && planting.length>0 ? (
        <div>
          <h4>Plantation / Repiquage / Rempotage</h4>
          { planting.map((variety,index) => (
          <div key={index}>{variety.name}</div>
          )) }
        </div>
        ) : null }

        { !!harvest && harvest.length>0 ? (
        <div>
          <h4>Récoltes</h4>
          { harvest.map((variety,index) => (
          <div key={index}>{variety.name}</div>
          )) }
        </div>
        ) : null }
      </div>

    </div>
  );
}

Month.propTypes = {
  month: PropTypes.number        // de 1 a 12
};
