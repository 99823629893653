import React, { useState} from 'react';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button     from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Fab from '@material-ui/core/Fab';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import MapIcon from '@material-ui/icons/Map';
import CreateIcon from '@material-ui/icons/Create';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import RoomIcon from '@material-ui/icons/Room';
import DoneIcon from '@material-ui/icons/Done';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import ModalYear      from   './modals/ModalYear';
import ModalHarvest      from   './modals/ModalHarvest';
import ModalHelp      from   './modals/ModalHelp';

import Notes      from   './components/Notes';

import HelpBox    from '../elements/HelpBox';

import NotesPage        from   './yearPages/NotesPage';
import HarvestPage      from   './yearPages/HarvestPage';
import InventoriesPage  from   './yearPages/InventoriesPage';
import StatsPage        from   './yearPages/StatsPage';
import MapsPage         from   './yearPages/MapsPage';

import S_App              from   '../services/S_App';
import S_Notes            from   '../services/S_Notes';

import './YearsManager.scss';




const FRAME_MAPS = 'MAPS';
const FRAME_NOTES = 'NOTES';
const FRAME_STATS = 'STATS';
const FRAME_INVENTORY = 'INVENTORY';
const FRAME_HARVEST = 'HARVEST';

export default function YearsManager(props) {
  let { categories,varieties,bags,
        openedInventory,inventories,addInventory,closeInventory,removeOpenedInventory,removeInventory,checkInventoryItem,
        harvests,addHarvestInYear,updateHarvest,deleteHarvest,
        notes,addNote,removeNote,changeNote,changeNoteYear } = props;

  const [frame, setFrame] = React.useState(FRAME_MAPS);
  const [year, setYear] = React.useState((new Date()).getFullYear());      // TODO mettre par defaut l'annee courante
  const [modalYearOpened, setModalYearOpened] = React.useState(false);
  const [editedItem, setEditedItem] = React.useState(undefined);  
  const [modalHarvestOpened, setModalHarvestOpened] = React.useState(false);
  const [help, setHelp] = React.useState<false|true>(false);

  function editHavest(e,harvest){
    setEditedItem(harvest);
    setModalHarvestOpened(true);
  }
  function saveUpdateHarvest(e,harvest,datas){
    updateHarvest(e,harvest,datas);
    // reset de l'edit
    setEditedItem(undefined);
    setModalHarvestOpened(false);
  }

  let suffix = '';
  let title = '';
  let Element = null;
  let ModalElement = null;
  let onAdd = null;

  if(frame === FRAME_MAPS){
    suffix = 'maps';
    title = 'Cartes';
    Element = <MapsPage />;
  }
  else if(frame === FRAME_STATS){
    suffix = 'stats';
    title = 'Stats';
    Element = <StatsPage />;
  }
  else if(frame === FRAME_HARVEST){
    let currentYear_harvests = harvests.filter(harvest => harvest.year === year);
    // on tri le tout
    currentYear_harvests.sort(function(a, b) {
      var keyA = new Date(a.harvestdate),
        keyB = new Date(b.harvestdate);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });

    suffix = 'harvest';
    title = 'Récoltes';
    onAdd = (e) => setModalHarvestOpened(true);
    Element = <HarvestPage
                harvests={currentYear_harvests}
                categories={categories}
                varieties={varieties}
                edit={editHavest} />;
    ModalElement = modalHarvestOpened ? <ModalHarvest
                      isModalOpened={modalHarvestOpened}
                      closeModal={(e) => setModalHarvestOpened(false)}
                      categories={categories}
                      varieties={varieties}
                      addHarvestInYear={addHarvestInYear}
                      harvest={editedItem}
                      updateHarvest={saveUpdateHarvest}
                      deleteHarvest={deleteHarvest}
                      year={year}/> : null;
  }
  else if(frame === FRAME_INVENTORY){
    let currentYear_inventories = inventories.filter(inventory => inventory.year === year);
    // console.log(inventories,year,currentYear_inventories);

    suffix = 'inventory';
    title = 'Inventaire';
    onAdd = !openedInventory ? (e) => addInventory(e,year) : undefined;
    Element = <InventoriesPage
                openedInventory={openedInventory}
                inventories={currentYear_inventories}
                categories={categories}
                varieties={varieties}
                bags={bags}
                closeInventory={closeInventory}
                checkInventoryItem={checkInventoryItem}
                removeOpenedInventory={removeOpenedInventory}
                removeInventory={removeInventory} />;
  }
  else{
    let currentYear_notes = notes.filter(note => note.year === year);
    let currentYear_inventories = inventories.filter(inventory => inventory.year === year);
    let currentYear_harvests = harvests.filter(harvest => harvest.year === year);
    // console.log(notes,year,currentYear_notes);

    suffix = 'notes';
    title = 'Notes';
    onAdd = (e) => addNote(e,year);
    Element = <NotesPage
                notes={currentYear_notes}
                removeNote={removeNote}
                changeNote={changeNote}
                changeNoteYear={changeNoteYear}
                categories={categories}
                inventories={currentYear_inventories}
                harvests={currentYear_harvests} />;
  }

  return (
    <div className={'years-manager years-manager--'+suffix}>

      <Container maxWidth="lg">
      
        <div className="year" onClick={(e) => setModalYearOpened(true)}>{year}</div>

        { frame === FRAME_INVENTORY ? (
          <>
          <div className="help">
            <Fab color="primary" aria-label="aide" size="small" className="fab" onClick={(e) => setHelp(!help)}><HelpOutlineIcon /></Fab>
            <ModalHelp
              isModalOpened={help}
              closeModal={(e) => setHelp(false)}
              contents={
                <>
                  <p>On ne peut ouvrir qu'un seul inventaire à la fois. Il faut le clore pour en ouvrir un autre.</p>
                  <p>L'inventaire s'initialise sur l'état du stock.</p>
                  <p><HelpOutlineIcon color="secondary"/> : A valider</p>
                  <p><DoneIcon className="green" /> : Stock validé</p>
                  <p><AddIcon /> : Ajouter un élément de stock</p>
                </>} />
          </div>
          </>
        ) : null }
        

        <h2>{title}</h2>
        <div>Work in progress</div>
        
        {Element}

      </Container>

      <div className="bottom-navigation">
        {onAdd ? <Fab color="primary" aria-label="add" size="small" className="fab" onClick={onAdd}><AddIcon /></Fab> : null}
        <BottomNavigation
          value={frame}
          onChange={(event, newValue) => {
            setFrame(newValue);
          }}
          showLabels>
          <BottomNavigationAction label="Cartes" value={FRAME_MAPS} icon={<MapIcon />} />
          <BottomNavigationAction label="Notes" value={FRAME_NOTES} icon={<CreateIcon />} />
          <BottomNavigationAction label="Stats" value={FRAME_STATS} icon={<MultilineChartIcon />} />
          <BottomNavigationAction label="Inventaire" value={FRAME_INVENTORY} icon={<StorefrontIcon />} className={openedInventory ? 'opened' : ''}/>
          <BottomNavigationAction label="Récoltes" value={FRAME_HARVEST} icon={<LocalDiningIcon />} />
        </BottomNavigation>
      </div>

      <ModalYear
          isModalOpened={modalYearOpened}
          closeModal={(e) => setModalYearOpened(false)}
          year={year}
          onYearChange={(newYear) => setYear(newYear)}/>

      {ModalElement}
    </div>
  );
};
