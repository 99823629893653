import S_App    from   '../services/S_App';
import {DEFAULT_SEEDS_VARIETIES}   from   '../services/CATEGORIES';

let S_SeedsVarieties = {
  STORAGE_KEY_VARIETIES : 'varieties',

  seeds_varieties : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- fetch()...');
    var seeds_varieties = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_SeedsVarieties.STORAGE_KEY_VARIETIES];
    if(seeds_varieties){
      S_SeedsVarieties.seeds_varieties = JSON.parse(seeds_varieties);
    }else{
      S_SeedsVarieties.seeds_varieties = DEFAULT_SEEDS_VARIETIES;
    }
      
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- fetch() seeds_varieties',S_SeedsVarieties.seeds_varieties);
    if(cb)
      cb();
  },
  store: (cb) => { 
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_SeedsVarieties.STORAGE_KEY_VARIETIES] = JSON.stringify(S_SeedsVarieties.seeds_varieties);
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- store() seeds_varieties',S_SeedsVarieties.seeds_varieties); 
    if(cb)
      cb();
  },

  getSeedsVariety: (id:number|string) => {
    return S_App.getByID(S_SeedsVarieties.seeds_varieties,id);
  },
  getSeedsVarieties: (categoryID:number,familyID:number) => {
    return S_App.getByFilters(S_SeedsVarieties.seeds_varieties,[{attribute:'categoryID',value:categoryID},{attribute:'familyID',value:familyID}]);
  },


  add: (categoryID,familyID, cb) => {
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- add()...');
    let currentDate = (new Date()).getTime();
    let newOne = {
      id: currentDate,
      creationdate: currentDate,
      lastupdate: currentDate,

      categoryID: categoryID,
      familyID: familyID,
      name: 'Nouvelle variété'
    };
    if(!S_SeedsVarieties.seeds_varieties)
      S_SeedsVarieties.seeds_varieties = [];
    S_SeedsVarieties.seeds_varieties.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- add() added',newOne);
    S_SeedsVarieties.store(() => {
      if(cb) cb(newOne);
    });
  },
  delete(item,cb){
    if(!S_SeedsVarieties.seeds_varieties || S_SeedsVarieties.seeds_varieties.length===0 || !item){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- delete()',item,'...'); 
    let foundIndex = S_App.getIndexOfByID(S_SeedsVarieties.seeds_varieties,item.id);
    if(foundIndex !== -1){
      S_SeedsVarieties.seeds_varieties.splice(foundIndex,1);
      if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- delete() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_SeedsVarieties.tsx -- delete() not found'); 
    }
    S_SeedsVarieties.store(() => {
      if(cb) cb();
    });
  },
  update(item,attribute,value,cb){
    if(!S_SeedsVarieties.seeds_varieties || S_SeedsVarieties.seeds_varieties.length===0 || !item){
      if(cb) cb();
      return;
    }
     
    let currentDate = (new Date()).getTime();
    let foundData = undefined;
    for(var i = 0; i < S_SeedsVarieties.seeds_varieties.length; i++){
      if(S_SeedsVarieties.seeds_varieties[i].id !== item.id)
        continue;
      foundData = S_SeedsVarieties.seeds_varieties[i];
      if('creationdate'===attribute || 'lastupdate'===attribute){
        foundData[attribute] = value!==undefined ? (new Date(value+'T12:00:00')).getTime() : undefined;
        foundData.lastupdate = currentDate;
      }else{
        foundData[attribute] = value;
        foundData.lastupdate = currentDate;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_SeedsVarieties.tsx -- update() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- update() updated',foundData);
    S_SeedsVarieties.store(() => {
      if(cb) cb(foundData);
    });
  },
  toggleMonth(item,attribute,month,cb){
    if(!S_SeedsVarieties.seeds_varieties || S_SeedsVarieties.seeds_varieties.length===0 || !item){
      if(cb) cb();
      return;
    }
     
    let currentDate = (new Date()).getTime();
    let foundData = undefined;
    for(var i = 0; i < S_SeedsVarieties.seeds_varieties.length; i++){
      if(S_SeedsVarieties.seeds_varieties[i].id !== item.id)
        continue;
      foundData = S_SeedsVarieties.seeds_varieties[i];
      if(foundData[attribute]){
        if(foundData[attribute].indexOf(month) === -1){
          foundData[attribute].push(month);
          foundData.lastupdate = currentDate;
        }else{
          foundData[attribute].splice(foundData[attribute].indexOf(month),1);
          foundData.lastupdate = currentDate;
        }
      }else{
        foundData[attribute] = [];
        foundData[attribute].push(month);
        foundData.lastupdate = currentDate;
      }
      break;
    }
    if(!foundData)
      if(S_App.isLogDebug) console.warn('-- S_SeedsVarieties.tsx -- toggleMonth() not found'); 
    if(foundData)
      if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- toggleMonth() updated',foundData);
    S_SeedsVarieties.store(() => {
      if(cb) cb(foundData);
    });
  },

  deleteAll(cb){
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- deleteAll()','...'); 
    S_SeedsVarieties.seeds_varieties = [];
    if(S_App.isLogDebug) console.log('-- S_SeedsVarieties.tsx -- deleteAll() deleted'); 
    S_SeedsVarieties.store(() => {
      if(cb) cb();
    });
  },


  getSeedsVarieties_bySeedlingMonth: (month) => {
    if(!S_SeedsVarieties.seeds_varieties || S_SeedsVarieties.seeds_varieties.length===0 || month===undefined){
      return [];
    }
    const matchingVarieties = S_SeedsVarieties.seeds_varieties.filter(variety => !!variety.seedlingMonths && variety.seedlingMonths.indexOf(month) !== -1);
    return matchingVarieties;
  },
  getSeedsVarieties_byPlantingMonth: (month) => {
    if(!S_SeedsVarieties.seeds_varieties || S_SeedsVarieties.seeds_varieties.length===0 || month===undefined){
      return [];
    }
    const matchingVarieties = S_SeedsVarieties.seeds_varieties.filter(variety => !!variety.plantingMonths && variety.plantingMonths.indexOf(month) !== -1);
    return matchingVarieties;
  },
  getSeedsVarieties_byHarvestMonth: (month) => {
    if(!S_SeedsVarieties.seeds_varieties || S_SeedsVarieties.seeds_varieties.length===0 || month===undefined){
      return [];
    }
    const matchingVarieties = S_SeedsVarieties.seeds_varieties.filter(variety => !!variety.harvestMonths && variety.harvestMonths.indexOf(month) !== -1);
    return matchingVarieties;
  }

};
export default S_SeedsVarieties;