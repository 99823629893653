import React from 'react';
import PropTypes from 'prop-types';

import Modal  from '@material-ui/core/Modal';
import Grid   from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';

import Typography from '@material-ui/core/Typography';

import S_App         from   '../../services/S_App';
import S_Categories  from   '../../services/S_Categories';
import S_SeedsVarieties   from   '../../services/S_SeedsVarieties';
import S_SeedsBags        from   '../../services/S_SeedsBags';
import './Modal.scss';

type ModalSeedsVarietyProps = {
  isModalOpened: boolean;
  closeModal: any;

  item: any;
  onClickDelete: any;
  onFieldChange: any;
  onMonthChange: any;
};

export default function ModalSeedsVariety(props:ModalSeedsVarietyProps) {
  const [extend, setExtend] = React.useState(undefined);    // accordeon ouvert


  // componentDidUpdate(prevProps, prevState){
  //   if(prevProps.item !== this.props.item){
  //     this.setState((state,props) => ({
  //       item: props.item
  //     }));
  //   }
  //   if(prevProps.item===undefined && this.props.item!==undefined){
  //     this.setState((state,props) => ({
  //       extend: false
  //     }));
  //   }
  // }

  function handleExtend(e) {
    setExtend(!extend);
  }



  let {item,isModalOpened,closeModal,onClickDelete,onFieldChange,onMonthChange} = props;

  let variety_bags = item ? S_SeedsBags.getSeedsBags(item.id) : undefined;

  if(!item)
    return null;

  let category = S_Categories.getSeedsCategory(item.categoryID);
  let family = category ? S_Categories.getSeedsFamily(category,item.familyID) : undefined;

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents">

        <div className="modal-form">
          <form className="edition" noValidate autoComplete="off">

            <Grid container justify="center">
              <Grid item xs={6}>
                <TextField label="Catégorie" variant="outlined" type="text" disabled={true}
                  value={category ? category.label.fr : ''}/>
              </Grid>
              <Grid item xs={6}>
                <TextField label="Famille" variant="outlined" type="text" disabled={true}
                  value={family ? family.label.fr : ''}/>
              </Grid>
            </Grid>

            <div className="fieldRow">
              <TextField label="Nom de la variété" variant="outlined" type="text" 
                value={item.name}
                onChange={(e) => onFieldChange(e,'name',e.target.value)}/>
            </div>

            <div className="fieldRow">
              <Button variant="contained" fullWidth={true} onClick={handleExtend}>Autres paramètres</Button>

              {extend ? (
              <div>

                <div className="fieldRow">
                  <div>Semi</div>
                  {[1,2,3,4,5,6,7,8,9,10,11,12].map((month,index) => {
                    let selected = item.seedlingMonths ? item.seedlingMonths.indexOf(month)!==-1 : false;
                    return (
                      <Button key={index} 
                        size="small" 
                        variant={selected ? 'contained' : 'outlined'} 
                        onClick={(e) => onMonthChange(e,item,'seedlingMonths',month)}>{month}</Button>
                    );
                  })}
                </div>

                <div className="fieldRow">
                  <div>Plantation / Repiquage / Rempotage</div>
                  {[1,2,3,4,5,6,7,8,9,10,11,12].map((month,index) => {
                    let selected = item.plantingMonths ? item.plantingMonths.indexOf(month)!==-1 : false;
                    return (
                      <Button key={index} 
                        size="small" 
                        variant={selected ? 'contained' : 'outlined'} 
                        onClick={(e) => onMonthChange(e,item,'plantingMonths',month)}>{month}</Button>
                    );
                  })}
                </div>

                <div className="fieldRow">
                  <div>Récolte</div>
                  {[1,2,3,4,5,6,7,8,9,10,11,12].map((month,index) => {
                    let selected = item.harvestMonths ? item.harvestMonths.indexOf(month)!==-1 : false;
                    return (
                      <Button key={index} 
                        size="small" 
                        variant={selected ? 'contained' : 'outlined'} 
                        onClick={(e) => onMonthChange(e,item,'harvestMonths',month)}>{month}</Button>
                    );
                  })}
                </div>

              </div>
              ) : null}
            </div>

          </form>
        </div>

        <div className="modal-actions">
          <Grid container spacing={2} justify="center">
            <Grid item xs={6}>
              <Button variant="contained" fullWidth={true} disabled={!!variety_bags && variety_bags.length>0} 
                onClick={onClickDelete}>Supprimer</Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" color="primary" fullWidth={true} 
                onClick={closeModal}>Fermer</Button>
            </Grid>
          </Grid>
        </div>

      </div>
    </Modal>
  );
}