import React, { useState} from 'react';
import PropTypes from 'prop-types';

import Button     from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';

import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import S_App      from '../../services/S_App';
import S_Harvests from '../../services/S_Harvests';

// import './HarvestPage.scss';


export default function HarvestPage(props) {
  let {categories,varieties,harvests,edit} = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChangeAccordion = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // calcul totaux
  let big_array = S_Harvests.calcTotals(categories,varieties,harvests);
  
  console.log('harvests array',big_array);

  return (
    <div>
      <div className="harvest-total">

        { big_array.map((familyHarvest,index) => {
            let category = familyHarvest.category;
            let family = familyHarvest.family;
            let varieties = familyHarvest.varieties;
            let pieces = familyHarvest.totalPieces;
            let grammes = familyHarvest.totalGrammes;
            let harvested = familyHarvest.harvested;

            if(!harvested)
              return null;

            let piecesActive = family && family.harvest.units.indexOf('p')!==-1;
            let grammesActive = family && family.harvest.units.indexOf('g')!==-1;
            let severalGrammesActive = family && family.harvest.formats && family.harvest.formats.length>0;

            return (
              <Accordion key={index} expanded={expanded === family.id} onChange={handleChangeAccordion(family.id)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header">
                  <div className="accordion-family">{ family.label.fr }</div>
                  <div className="accordion-total">
                    { piecesActive && pieces>0 ? <div>{pieces} pièce(s)</div> : null }
                    { grammesActive && severalGrammesActive ? (
                      <>
                      { family.harvest.formats.map((format,index2) => {
                        if(grammes===undefined || grammes[index2]===undefined)
                          return null;
                        return <div key={index2}>{format} : { grammes[index2] }g</div>;
                      }) }
                      </>
                    ) : null }
                    { grammesActive && !severalGrammesActive ? (
                      <div>{ grammes[0] }g</div>
                    ) : null }
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  liste des récoltes?
                </AccordionDetails>
              </Accordion>
            );
        }) }
      </div>

      <div className="harvest-history">
        <h3>Historique</h3>
        <div className="list">
          { harvests.map((harvest,index) => {
            let category = S_App.getByID(categories,harvest.categoryID);
            let family = S_App.getByID(category.families,harvest.familyID);
            let variety = S_App.getByID(varieties,harvest.varietyID);
            let harvestdateFormatted = harvest.harvestdate ? (new Date(harvest.harvestdate)).toLocaleDateString("fr-FR", { year: 'numeric', month: 'short', day: 'numeric', weekday: undefined, hour: undefined, minute: undefined }) : null;

            if(!family)
              return <li>-- fmille inconnue --</li>;

            let piecesActive = family && family.harvest.units.indexOf('p')!==-1;
            let grammesActive = family && family.harvest.units.indexOf('g')!==-1;
            let severalGrammesActive = family && family.harvest.formats && family.harvest.formats.length>0;

            return (
              <div key={index} className="list-item">
                <Grid container>
                  <Grid item xs={2}>
                    { harvestdateFormatted }
                  </Grid>
                  <Grid item xs={3}>
                    <strong>{ family.label.fr }</strong>
                    { variety ? <span>- {variety.name}</span> : null }
                  </Grid>
                  <Grid item xs={6}>
                    { piecesActive && harvest.pieces!==undefined ? <div> { harvest.pieces } pièce(s)</div> : null }
                    { grammesActive && severalGrammesActive ? (
                      <>
                      { family.harvest.formats.map((format,index2) => {
                        if(harvest.grammes===undefined || harvest.grammes[index2]===undefined)
                          return null;
                        return <div key={index2}>{format} : { harvest.grammes[index2] }g</div>;
                      }) }
                      </>
                    ) : null }
                    { grammesActive && !severalGrammesActive ? (
                      <div>{ harvest.grammes[0] }g</div>
                    ) : null }
                  </Grid>
                  <Grid item xs={1}>
                    <EditIcon fontSize="small" color="primary" onClick={(e) => edit(e,harvest)}/>
                  </Grid>
                </Grid>
              </div>
            );
          }) }
        </div>
      </div>
    </div>
  );
};
