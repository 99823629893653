import React, { useState} from 'react';
import PropTypes from 'prop-types';

import Button     from '@material-ui/core/Button';

import EditIcon from '@material-ui/icons/Edit';

import Notes      from   '../components/Notes';

// import './NotesPage.scss';

import S_App         from   '../../services/S_App';

export default function NotesPage(props) {
  let {
    notes,addNote,removeNote,changeNote,changeNoteYear,
    families,
    inventories,
    harvests
  } = props;

  let history_all = [];

  // on ajoute les elements par date
  if(inventories && inventories.length>0){
    for(var i = 0; i < inventories.length; i++){
      let inventory = inventories[i];
      let closedate = inventory.closedate;
      history_all.push({
        date: closedate,
        itemType: 'inventory',
        item: inventory
      });
    }
  }
  if(harvests && harvests.length>0){
    for(var i = 0; i < harvests.length; i++){
      let harvest = harvests[i];
      let harvestdate = harvest.harvestdate;
      history_all.push({
        date: harvestdate,
        itemType: 'harvest',
        item: harvest
      });
    }
  }
  // on tri le tout
  history_all.sort(function(a, b) {
    var keyA = new Date(a.date),
      keyB = new Date(b.date);
    // Compare the 2 dates
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  return (
    <div>
      <div className="notes-general">
        <Notes 
          notes={notes}
          removeNote={removeNote}
          changeNote={changeNote}
          changeNoteYear={changeNoteYear} />
      </div>

      <div className="notes-history">
        <h3>Historique</h3>
        <ul>
          <li>Ma. 5 Septembre : Nettoyage des tomates
            <Button variant="contained" size="small"><EditIcon /></Button>
          </li>
          <li>Ma. 21 Mai : Semi des petits pois
            <Button variant="contained" size="small"><EditIcon /></Button>
          </li>

          { history_all.map((item,index) => {
            let dateFormatted = item && item.date ? (new Date(item.date)).toLocaleDateString("fr-FR", { year: undefined, month: 'short', day: 'numeric', weekday: undefined, hour: undefined, minute: undefined }) : null;
    
            if(item.itemType === 'inventory')
              return (
                <li className="auto">
                  {dateFormatted} : <span className="type">Clôture d'inventaire</span>
                </li>
              );

            if(item.itemType === 'harvest'){
              let harvestFamily = S_App.getByID(families,item.item.familyID);
              return (
                <li className="auto">
                  {dateFormatted} : <span className="type">Récolte de { harvestFamily ? harvestFamily.label.fr : '?' }</span>
                </li>
              );
            }
              

            return (
              <li>
                {dateFormatted} : ?
              </li>
            );
          }) }
        </ul>
      </div>
    </div>
  );
};
