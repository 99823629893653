import S_App    from   '../services/S_App';
import {I_Harvest} from   '../services/I_Harvest';

let TEST_HARVESTS = [];

let S_Harvests = {
  STORAGE_KEY_HARVESTS : 'harvests',

  harvests : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Harvests.tsx -- fetch()...');
    var harvests = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Harvests.STORAGE_KEY_HARVESTS];
    if(harvests){
      S_Harvests.harvests = JSON.parse(harvests);
    }else{
      S_Harvests.harvests = TEST_HARVESTS;
    }
      
    if(S_App.isLogDebug) console.log('-- S_Harvests.tsx -- fetch() harvests',S_Harvests.harvests);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Harvests.STORAGE_KEY_HARVESTS] = JSON.stringify(S_Harvests.harvests);
    if(S_App.isLogDebug) console.log('-- S_Harvests.tsx -- store() harvests',S_Harvests.harvests); 
    if(cb)
      cb();
  },


  getHarvest: (id:number|string) => {
    return S_App.getByID(S_Harvests.harvests,id);
  },

  addHarvestInYear(year,datas,cb){
    let dateNow = (new Date()).getTime();
    let newOne = {
      id: dateNow,
      lastupdate: dateNow,
      year: year,
      harvestdate: datas.harvestdate,  
      categoryID: datas.categoryID,   
      familyID: datas.familyID,  
      varietyID: datas.varietyID,  
      pieces: datas.pieces,  
      grammes: datas.grammes
    };
    if(!S_Harvests.harvests)
      S_Harvests.harvests = [];
    S_Harvests.harvests.push(newOne);
    S_Harvests.store(() => {
      if(cb) cb();
    });
  },

  updateHarvest(harvest,datas,cb){
    if(!S_Harvests.harvests || S_Harvests.harvests.length===0 || !harvest){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Harvests.harvests.length; i++){
      if(S_Harvests.harvests[i].id === harvest.id){
        foundData = S_Harvests.harvests[i];
        foundData.harvestdate = datas.harvestdate;  
        foundData.categoryID = datas.categoryID;  
        foundData.familyID = datas.familyID;  
        foundData.varietyID = datas.varietyID; 
        foundData.pieces = datas.pieces;  
        foundData.grammes = datas.grammes;
        foundData.lastupdate = (new Date()).getTime();
        break;
      }
    }
    S_Harvests.store(() => {
      if(cb) cb(foundData);
    });
  },

  deleteHarvest(harvest,cb){
    if(!S_Harvests.harvests || S_Harvests.harvests.length===0 || !harvest){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Harvests.tsx -- deleteHarvest() delete',harvest,'...'); 
    let foundIndex = S_App.getIndexOfByID(S_Harvests.harvests,harvest.id);
    if(foundIndex !== -1){
      S_Harvests.harvests.splice(foundIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Harvests.tsx -- deleteHarvest() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Harvests.tsx -- deleteHarvest() not found');
    }
    S_Harvests.store(() => {
      if(cb) cb();
    });
  },

  deleteAll(cb){
    if(S_App.isLogDebug) console.log('-- S_Harvests.tsx -- deleteAll()','...'); 
    S_Harvests.harvests = [];
    if(S_App.isLogDebug) console.log('-- S_Harvests.tsx -- deleteAll() deleted'); 
    S_Harvests.store(() => {
      if(cb) cb();
    });
  },



  calcTotals(categories,varieties,harvests){
    let big_array = [];

    if(!categories || categories.length===0)
      return [];

    // pour chaque categorie
    for(var c = 0; c < categories.length; c++){
      let category = categories[c];

      let families = category.families;

      // pour chaque famille
      for(var f = 0; f < families.length; f++){
        let family = families[f];
        let piecesActive = family && family.harvest.units.indexOf('p')!==-1;
        let grammesActive = family && family.harvest.units.indexOf('g')!==-1;
        let severalGrammesActive = family && family.harvest.formats && family.harvest.formats.length>0;

        let harvested = false;
        let totalPiecesFamily = 0;
        let totalGrammesFamily = family.harvest && family.harvest.formats.length>1 ? new Array(family.harvest.formats.length) : [0];
        for(var i = 0; i < totalGrammesFamily.length; i++){
          totalGrammesFamily[i] = 0;
        }

        // pour chaque variete
        let familyVarieties = [];
        for(var v = 0; v < varieties.length; v++){
          let variety = varieties[v];
          if(variety.familyID !== family.id)
            continue;

          let totalPieces = 0;
          let totalGrammes = family.harvest && family.harvest.formats.length>1 ? new Array(family.harvest.formats.length) : [0];
          for(var i = 0; i < totalGrammes.length; i++){
            totalGrammes[i] = 0;
          }

          // on regarde les recoltes
          for(var h = 0; h < harvests.length; h++){
            let harvest = harvests[h];

            if(harvest.categoryID!==category.id || harvest.familyID!==family.id || harvest.varietyID!==variety.id)
              continue;

            if(piecesActive && harvest.pieces!==undefined){
              totalPieces += harvest.pieces;
              totalPiecesFamily += harvest.pieces;
            }
            if(grammesActive && !severalGrammesActive && harvest.grammes[0]!==undefined){
              totalGrammes[0] += harvest.grammes[0];
              totalGrammesFamily[0] += harvest.grammes[0];
            }
            if(grammesActive && severalGrammesActive){
              for(var g = 0; g < harvest.grammes.length; g++){
                let gr = harvest.grammes[g];
                if(gr!==undefined){
                  totalGrammes[g] += harvest.grammes[g];
                  totalGrammesFamily[g] += harvest.grammes[g];
                }
              }
            }
            harvested = true;
          }

          familyVarieties.push({
            variety: variety,
            totalPieces: totalPieces,
            totalGrammes: totalGrammes
          });
        }

        // si pas de varietes
        let totalPieces = 0;
        let totalGrammes = [0];
        for(var h = 0; h < harvests.length; h++){
          let harvest = harvests[h];

          if(harvest.categoryID!==category.id || harvest.familyID!==family.id || harvest.varietyID!==undefined)
            continue;

          if(piecesActive && harvest.pieces!==undefined){
            totalPiecesFamily += harvest.pieces;
          }
          if(grammesActive && !severalGrammesActive && harvest.grammes[0]!==undefined){
            totalGrammesFamily[0] += harvest.grammes[0];
          }
          if(grammesActive && severalGrammesActive){
            for(var g = 0; g < harvest.grammes.length; g++){
              let gr = harvest.grammes[g];
              if(gr!==undefined){
                totalGrammesFamily[g] += harvest.grammes[g];
              }
            }
          }
          harvested = true;
        }

        big_array.push({
          category: category,
          family: family,
          harvested: harvested,
          totalPieces: totalPiecesFamily,
          totalGrammes: totalGrammesFamily,
          varieties: familyVarieties
        });
      }
    }
    return big_array;
  }

};
export default S_Harvests;