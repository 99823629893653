import React, { useState} from 'react';
import PropTypes from 'prop-types';

import Button     from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';

import EditIcon from '@material-ui/icons/Edit';
import RoomIcon from '@material-ui/icons/Room';

// import './MapsPage.scss';


export default function MapsPage(props) {
  let {} = props;

  return (
    <div>
      <div>Cet encart affichera :
      <br/>* la carte théorique,
      <br/>* la carte effective</div>

      <div className="localization">
        <Fab aria-label="localize" size="small" className="fab"><RoomIcon /></Fab>
      </div>
    </div>
  );
};
