let DEFAULT_SEEDS_CATEGORIES = [
  {
    'id':10,
    'label': {
      'fr':'Fruits'
    },
    'families': [
      {
        'id':10,
        'label':{
          'fr':'Pommes'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','en morceaux','pelures']
        }
      },
      {
        'id':20,
        'label':{
          'fr':'Poires'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','en morceaux','pelures']
        }
      },
      {
        'id':30,
        'label':{
          'fr':'Prunes'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','en morceaux','pelures']
        }
      },
      {
        'id':40,
        'label':{
          'fr':'Pêches'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','en morceaux','pelures']
        }
      },
      {
        'id':50,
        'label':{
          'fr':'Abricots'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers','en morceaux','pelures']
        }
      },
      {
        'id':60,
        'label':{
          'fr':'Noisettes'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','sans coquille']
        }
      },
      {
        'id':70,
        'label':{
          'fr':'Kiwis'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':80,
        'label':{
          'fr':'Oranges'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières']
        }
      },
      {
        'id':90,
        'label':{
          'fr':'Citrons'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':100,
        'label':{
          'fr':'Kakis'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':110,
        'label':{
          'fr':'Cerises'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','en morceaux']
        }
      }
    ]
  },
  {
    'id':20,
    'label': {
      'fr':'Baies'
    },
    'families': [
      {
        'id':10,
        'label':{
          'fr':'Framboises'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':20,
        'label':{
          'fr':'Cassis'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':30,
        'label':{
          'fr':'Groseilles'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':40,
        'label':{
          'fr':'Myrtilles'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':50,
        'label':{
          'fr':'Fraises'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':60,
        'label':{
          'fr':'Mûres'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      }
    ]
  },
  {
    'id':30,
    'label': {
      'fr':'Légumes racines'
    },
    'families':[
      {
        'id':10,
        'label':{
          'fr':'Carottes'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières']
        }
      },
      {
        'id':20,
        'label':{
          'fr':'Radis'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':30,
        'label':{
          'fr':'Panais'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':40,
        'label':{
          'fr':'Rutabagas'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':50,
        'label':{
          fr: 'Pommes de terre'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': []
        }
      },
      {
        'id':50,
        'label':{
          fr: 'Patates douces'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': []
        }
      }
    ]
  },
  {
    'id':40,
    'label': {
      'fr':'Légumes bulbes'
    },
    'families':[
      {
        'id':10,
        'label':{
          'fr':'Oignons'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':20,
        'label':{
          'fr':'Ails'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':30,
        'label':{
          'fr':'Echalottes'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières']
        }
      },
      {
        'id':40,
        'label':{
          'fr':'Fenouils'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      }
    ]
  },
  {
    'id':50,
    'label': {
      'fr':'Légumes fruits'
    },
    'families':[
      {
        'id':10,
        'label':{
          'fr':'Tomates'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','pelures']
        }
      },
      {
        'id':20,
        'label':{
          'fr':'Courgettes'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','pelures']
        }
      },
      {
        'id':30,
        'label':{
          'fr':'Aubergines'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','pelures']
        }
      },
      {
        'id':40,
        'label':{
          'fr':'Concombres'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers','pelures']
        }
      },
      {
        'id':50,
        'label':{
          'fr':'Cornichons'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':60,
        'label':{
          'fr':'Piments'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':70,
        'label':{
          'fr':'Poivrons'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':80,
        'label':{
          'fr':'Courges'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','en morceaux']
        }
      },
      {
        'id':90,
        'label':{
          'fr':'Melons'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      },
      {
        'id':100,
        'label':{
          'fr':'Petits pois'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers','écossés']
        }
      },
      {
        'id':110,
        'label':{
          'fr':'Fèves'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entières','écossées','sans peau']
        }
      },
      {
        'id':120,
        'label':{
          'fr':'Haricots'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers']
        }
      }
    ]
  },
  {
    'id':60,
    'label': {
      'fr':'Légumes feuilles & tiges'
    },
    'families':[
      {
        'id':10,
        'label':{
          'fr':'Laitues'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': []
        }
      },
      {
        'id':20,
        'label':{
          'fr':'Rhubarbes'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': []
        }
      },
      {
        'id':30,
        'label':{
          'fr':'Poireaux'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers','blancs']
        }
      },
      {
        'id':40,
        'label':{
          'fr':'Choux'
        },
        'harvest': {
          'units': ['g','p'],
          'formats': ['entiers','sans feuilles']
        }
      }
    ]
  },
  {
    'id':60,
    'label': {
      'fr':'Aromatiques'
    },
    'families':[
      {
        'id':10,
        'label':{
          'fr':'Thyms'
        },
        'harvest': {
          'units': ['g'],
          'formats': ['entiers','feuilles']
        }
      },
      {
        'id':20,
        'label':{
          'fr':'Romarins'
        },
        'harvest': {
          'units': ['g'],
          'formats': ['entiers','feuilles']
        }
      },
      {
        'id':30,
        'label':{
          'fr':'Ciboulettes'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':40,
        'label':{
          'fr':'Estragons'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':50,
        'label':{
          'fr':'Menthes'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':60,
        'label':{
          'fr':'Basilics'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      },
      {
        'id':70,
        'label':{
          'fr':'Sauges'
        },
        'harvest': {
          'units': ['g'],
          'formats': []
        }
      }
    ]
  }
];

let DEFAULT_SEEDS_VARIETIES = [
  {
    'id':1,

    'categoryID': 50,
    'familyID': 10,
    'name': 'Exemple tomates A',

    'seedlingMonths': [1,4,5,6,7],
    'harvestMonths': []
  },
  {
    'id':2,

    'categoryID': 50,
    'familyID': 10,
    'name': 'Exemple tomates B',

    'seedlingMonths': [5,6,7,8],
    'harvestMonths': []
  },
  {
    'id':3,

    'categoryID': 50,
    'familyID': 20,
    'name': 'Courgettes vertes',

    'seedlingMonths': [5,6,7,8],
    'harvestMonths': []
  }
];


let DEFAULT_SEEDS_BAGS = [
  {
    'id':1,
    'parentID':undefined,

    'varietyID': 1,
    'name': 'Tomates A 2019',
    'harvestdate': 1599981443926,

    'isEmpty': true,
    'isArchived': true
  },
  {
    'id':2,
    'parentID':1,

    'varietyID': 1,
    'name': 'Tomates A 2020',
    'harvestdate': 1599981443926,

    'isEmpty': true,
    'isArchived': false
  },
  {
    'id':3,
    'parentID':1,

    'varietyID': 1,
    'name': 'Tomates A 2020 (2)',
    'harvestdate': 1599981443926,

    'isEmpty': false,
    'isArchived': false
  }
];
export {DEFAULT_SEEDS_CATEGORIES, DEFAULT_SEEDS_VARIETIES, DEFAULT_SEEDS_BAGS};