import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Modal  from '@material-ui/core/Modal';
import Grid   from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import S_App         from   '../../services/S_App';
import './Modal.scss';



export default function ModalYear(props) {
  let {isModalOpened,closeModal,year,onYearChange} = props;
  const [selected, setSelected] = React.useState(year); 

  var currentYear = (new Date()).getFullYear();

  var years = [];
  for (var i = currentYear + 2; i > 2013; i--) {
    years.push(i);
  }

  function handleChange(value){
    setSelected(value);
    onYearChange(value);
  }

  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents">

        { year ? (
        <>

          <div className="modal-form">
            <form className="edition" noValidate autoComplete="off">

              <div className="fieldRow">
                <Select label="Année" variant="outlined" type="number" 
                  value={selected}
                  onChange={(e) => handleChange(e.target.value)}>
                  { years.map((y,index) => <MenuItem key={index} value={y}>{y}</MenuItem>) }
                </Select>
              </div>

            </form>
          </div>

          <div className="modal-actions">
            <Grid container spacing={2} justify="center">
              <Grid item xs={6}>
                
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" fullWidth={true} 
                  onClick={closeModal}>Fermer</Button>
              </Grid>
            </Grid>
          </div>

        </>
        ) : null}

      </div>
    </Modal>
  );
  
};