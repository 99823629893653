import React, { useState} from 'react';
import PropTypes from 'prop-types';

import Slider from "react-slick";

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button     from '@material-ui/core/Button';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import FilterDramaIcon from '@material-ui/icons/FilterDrama';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import S_App              from   '../services/S_App';
import S_SeedsVarieties   from   '../services/S_SeedsVarieties';

import Month from './elements/Month';
// import Version from '../elements/Version';
import { Version } from 'react-mui-pwa-tools';

import pkg from '../../package.json';

import './Dashboard.scss';

let Iframe = function(props) {
  return(         
    <div className="iframe">          
      <iframe src={props.src} height={props.height} width={props.width} id={props.id}/>         
    </div>
  );
};

const FRAME_BY_MONTH = 'BY_MONTH';
const FRAME_ALERTS = 'ALERTS';


type DashboardProps = {
  deleteAllVarieties: any;
  deleteAllHarvests: any;
};

export default function Dashboard(props:DashboardProps) {
  const [frame, setFrame] = React.useState(FRAME_BY_MONTH);

  let {deleteAllVarieties,deleteAllHarvests} = props;


  // Vue TODO by month (slick slider) -------------------------

  if(frame === FRAME_BY_MONTH){
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: (new Date()).getMonth()
    };

    // TODO : par defaut, se mettre sur le mois courant


    return (
      <div className="dashboard dashboard--by--month">
        <h2>Calendrier</h2>
        <p>Les données affichées sont basées sur les variétés créées dans la vue Stock.</p>

        <div className="slider-by-month">
          <Slider {...settings}>
            {[1,2,3,4,5,6,7,8,9,10,11,12].map((month,index) => <Month key={index} month={month} />)}
          </Slider>
        </div>

        <div className="bottom-navigation">
          <BottomNavigation
            value={frame}
            onChange={(event, newValue) => {
              setFrame(newValue);
            }}
            showLabels>
            <BottomNavigationAction label="Mois" value={FRAME_BY_MONTH} icon={<EventAvailableIcon />} />
            <BottomNavigationAction label="Alertes" value={FRAME_ALERTS} icon={<FilterDramaIcon />} />
            <BottomNavigationAction label="Compte" value={undefined} icon={<AccountCircleIcon />} />
          </BottomNavigation>
        </div>
      </div>
    );
  }

  
  // Vue alertes -------------------------

  if(frame === FRAME_ALERTS)
    return (
      <div className="dashboard dashboard--alerts">
        <h2>Alertes</h2>
        <div>Work in progress</div>
        <div>Cet encart affichera :
        <br/>* la meteo (si localisation paramétrée), avec warning si risques gel / grèle / sécheresse, 
        <br/>* les infos de capteurs IOT (si paramétrés)</div>

        <Iframe id="widget_autocomplete_preview"  width="100%" src="https://meteofrance.com/widget/prevision/784860" />

        https://api.meteo-concept.com/pricing

        https://openweathermap.org/

        <div className="bottom-navigation">
          <BottomNavigation
            value={frame}
            onChange={(event, newValue) => {
              setFrame(newValue);
            }}
            showLabels>
            <BottomNavigationAction label="Mois" value={FRAME_BY_MONTH} icon={<EventAvailableIcon />} />
            <BottomNavigationAction label="Alertes" value={FRAME_ALERTS} icon={<FilterDramaIcon />} />
            <BottomNavigationAction label="Compte" value={undefined} icon={<AccountCircleIcon />} />
          </BottomNavigation>
        </div>
      </div>
    );


  // Vue account -------------------------

  const isDEV = !!process.env && process.env.NODE_ENV === 'development';

  return (
    <div className="dashboard dashboard--account">

      <Container maxWidth="lg">

        <h2>Mon compte</h2>
        <div>Work in progress</div>
        <div>Cet encart affichera les infos et paramètres liés au compte :
        <br/>Etape 1 : datas localStorage - message expliquant ce qui est stocké en localStorage.
        <br/>Etape 2 : datas cloud - message expliquant ce qui est stocké en cloud, plus paramètres de login / deconnexion, récupération des datas en json, suppression définitive du compte...</div>
        
        <Button variant="contained" onClick={(e) => deleteAllVarieties(()=>{})}><DeleteForeverIcon />Supprimer toutes les variétés</Button>
        <Button variant="contained" onClick={(e) => deleteAllHarvests()}><DeleteForeverIcon />Supprimer toutes les récoltes</Button>

        <Version
          development={isDEV}
          version={pkg.version}
          lastUpdate={pkg.lastUpdate} />

      </Container>

      <div className="bottom-navigation">
        <BottomNavigation
          value={frame}
          onChange={(event, newValue) => {
            setFrame(newValue);
          }}
          showLabels>
          <BottomNavigationAction label="Mois" value={FRAME_BY_MONTH} icon={<EventAvailableIcon />} />
          <BottomNavigationAction label="Alertes" value={FRAME_ALERTS} icon={<FilterDramaIcon />} />
          <BottomNavigationAction label="Compte" value={undefined} icon={<AccountCircleIcon />} />
        </BottomNavigation>
      </div>
    </div>
  );
};

// export default Dashboard;
