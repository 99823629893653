import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';

import Modal  from '@material-ui/core/Modal';
import Grid   from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import S_App         from   '../../services/S_App';
import S_Categories  from   '../../services/S_Categories';
import S_SeedsVarieties   from   '../../services/S_SeedsVarieties';
import './Modal.scss';



export default function ModalHarvest(props) {
  let {
    isModalOpened,closeModal,
    categories,varieties,
    year,
    addHarvestInYear,
    harvest,
    updateHarvest,
    deleteHarvest
  } = props;

  console.log('Edit harvest :',harvest);

  let defaultCategory = harvest ? S_App.getByID(categories,harvest.categoryID) : (categories && categories.length>0 ? categories[0] : undefined);  // 1ere famille

  let defaultFamiliesList = defaultCategory ? defaultCategory.families : [];

  let defaultFamily = harvest ? S_App.getByID(defaultCategory.families,harvest.familyID) : (defaultFamiliesList && defaultFamiliesList.length>0 ? defaultFamiliesList[0] : undefined);  // 1ere famille

  let defaultVarietiesList = defaultFamily ? S_SeedsVarieties.getSeedsVarieties(defaultCategory.id,defaultFamily.id) : [];  // liste des varietes de la famille selectionnee

  let defaultVariety = harvest ? S_App.getByID(varieties,harvest.varietyID) : (defaultFamily && defaultVarietiesList.length>0 ? defaultVarietiesList[0] : undefined);
  
  let piecesActive = defaultFamily && defaultFamily.harvest.units.indexOf('p')!==-1;
  let grammesActive = defaultFamily && defaultFamily.harvest.units.indexOf('g')!==-1;
  let severalGrammesActive = defaultFamily && defaultFamily.harvest.formats && defaultFamily.harvest.formats.length>1;

  let defaultPieces = harvest ? harvest.pieces : undefined;

  let defaultGrammes = grammesActive && severalGrammesActive ? new Array(defaultFamily.harvest.formats.length) : [undefined];
  for(var i = 0; i < defaultGrammes.length; i++){
    defaultGrammes[i] = undefined;
  }
  if(harvest)
    defaultGrammes = [...harvest.grammes];

  let defaultHarvestdate = harvest ? S_App.formatDateForMaterial(harvest.harvestdate) : S_App.formatDateForMaterial(new Date());

  // let defaults = {
  //   harvestdate: defaultHarvestdate,  
  //   family: defaultFamily,  
  //   variety: defaultVariety,  
  //   pieces: defaultPieces,  
  //   grammes: defaultGrammes
  // };
  // console.log('default',defaults);


  const [category, setCategory] = React.useState(defaultCategory);
  const [family, setFamily] = React.useState(defaultFamily);
  const [familyVarieties, setFamilyVarieties] = React.useState(defaultVarietiesList);
  const [variety, setVariety] = React.useState(defaultVariety);
  const [pieces, setPieces] = React.useState(defaultPieces);
  const [grammes, setGrammes] = React.useState(defaultGrammes);
  const [harvestdate, setHarvestdate] = React.useState(defaultHarvestdate);

  function handleCategory(scategory){
    let varietiesList = family ? S_SeedsVarieties.getSeedsVarieties(scategory.id,category.families[0].id) : [];
    let variety = family && varietiesList.length>0 ? varietiesList[0] : undefined;
    setCategory(scategory);
    setFamily(family);
    setFamilyVarieties(varietiesList);
    setVariety(variety);
  }

  function handleFamily(sfamily){
    let varietiesList = sfamily ? S_SeedsVarieties.getSeedsVarieties(category.id,sfamily.id) : [];
    let variety = sfamily && varietiesList.length>0 ? varietiesList[0] : undefined;
    setFamily(sfamily);
    setFamilyVarieties(varietiesList);
    setVariety(variety);
  }

  function handleVariety(value){
    setVariety(value);
  }

  function save(e){
    if(!harvestdate)
      return;

    // console.log('save',family,variety,pieces,grammes,harvestdate);
    closeModal();

    let datas = {
      harvestdate: S_App.formatDateForMillis(harvestdate).getTime(),  
      categoryID: family ? category.id : undefined,  
      familyID: family ? family.id : undefined,  
      varietyID: variety ? variety.id : undefined,  
      pieces: pieces,  
      grammes: grammes
    };
    if(!harvest && addHarvestInYear)
      addHarvestInYear(e,year,datas);
    if(harvest && updateHarvest) 
      updateHarvest(e,harvest,datas);

    // puis on remet les valeurs par defaut
    setCategory(categories && categories.length>0 ? categories[0] : undefined);
    setFamily(category && category.families.length>0 ? category.families[0] : undefined);
    setVariety(family && S_SeedsVarieties.getSeedsVarieties(category.id,family.id) && S_SeedsVarieties.getSeedsVarieties(category.id,family.id).length>0 ? S_SeedsVarieties.getSeedsVarieties(category.id,family.id)[0] : undefined);
    setFamilyVarieties(family ? S_SeedsVarieties.getSeedsVarieties(category.id,family.id) : []);

    let piecesActive = family && family.harvest.units.indexOf('p')!==-1;
    let grammesActive = family && family.harvest.units.indexOf('g')!==-1;
    let severalGrammesActive = family && family.harvest.formats && family.harvest.formats.length>1;

    setPieces(undefined);
    let newArray = grammesActive && severalGrammesActive ? new Array(family.harvest.formats.length) : [undefined];
    for(var i = 0; i < newArray.length; i++){
      newArray[i] = undefined;
    }
    setGrammes(newArray);
    setHarvestdate(undefined);
  }

  function setGrammesItem(value,index){
    let newGrammes = [...grammes];
    newGrammes[index] = value;
    setGrammes(newGrammes);
  }

  function doDelete(e){
    closeModal();

    // puis on remet les valeurs par defaut
    setCategory(categories && categories.length>0 ? categories[0] : undefined);
    setFamily(category && category.families.length>0 ? category.families[0] : undefined);
    setVariety(family && S_SeedsVarieties.getSeedsVarieties(category.id,family.id) && S_SeedsVarieties.getSeedsVarieties(category.id,family.id).length>0 ? S_SeedsVarieties.getSeedsVarieties(category.id,family.id)[0] : undefined);
    setFamilyVarieties(family ? S_SeedsVarieties.getSeedsVarieties(category.id,family.id) : []);

    let piecesActive = family && family.harvest.units.indexOf('p')!==-1;
    let grammesActive = family && family.harvest.units.indexOf('g')!==-1;
    let severalGrammesActive = family && family.harvest.formats && family.harvest.formats.length>1;

    setPieces(undefined);
    let newArray = grammesActive && severalGrammesActive ? new Array(family.harvest.formats.length) : [undefined];
    for(var i = 0; i < newArray.length; i++){
      newArray[i] = undefined;
    }
    setGrammes(newArray);
    setHarvestdate(undefined);

    // on supprime vraiment
    deleteHarvest(e,harvest);
  }


  // let datas = {
  //   harvestdate: harvestdate,  
  //   family: family,  
  //   variety: variety,  
  //   pieces: pieces,  
  //   grammes: grammes
  // };
  // console.log('state',datas);


  return (
    <Modal
      open={isModalOpened}
      onClose={closeModal}>
      <div className="modal-contents">


          <div className="modal-form">
            <form className="edition" noValidate autoComplete="off">

              <Grid container justify="center">
                <Grid item xs={6}>
                  <Select label="Catégorie" variant="outlined" onChange={(e) => handleCategory(e.target.value)} value={category ? category : ''}
                    inputProps={{
                      name: 'category',            
                    }}>
                    <MenuItem value={undefined}>-</MenuItem>
                    { categories.map( (scategory,index) => <MenuItem key={index} value={scategory}>{scategory.label.fr}</MenuItem>)}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  { category ? (
                  <Select label="Famille" variant="outlined" onChange={(e) => handleFamily(e.target.value)} value={family ? family : ''}
                    inputProps={{
                      name: 'family',            
                    }}>
                    <MenuItem value={undefined}>-</MenuItem>
                    { defaultFamiliesList.map( (sfamily,index) => <MenuItem key={index} value={sfamily}>{sfamily.label.fr}</MenuItem>)}
                  </Select>
                  ) : null }
                </Grid>
              </Grid>

              { family ? (
              <div>
                { familyVarieties && familyVarieties.length>0 ? (
                <div className="fieldRow">
                  <Select label="Variété" variant="outlined" onChange={(e) => handleVariety(e.target.value)} value={variety ? variety : ''}>
                    <MenuItem value={undefined}>-</MenuItem>
                    { familyVarieties.map( (svariety,index) => <MenuItem key={index} value={svariety}>{svariety.name}</MenuItem>)}
                  </Select>
                </div>
                ) : null }

                { piecesActive ? (
                <div className="fieldRow">
                  <TextField label="Pièces" variant="outlined" type="number" onChange={(e) => setPieces(parseInt(e.target.value))} value={pieces}/>
                </div>
                ) : null }

                { grammesActive && !severalGrammesActive ? (
                <div className="fieldRow">
                  <TextField label="Quantité (g)" variant="outlined" type="number" onChange={(e) => setGrammes([parseInt(e.target.value)])} value={grammes[0]}/>
                </div>
                ) : null }

                { grammesActive && severalGrammesActive ? (
                <>
                { family.harvest.formats.map( (format,index) => (
                <div className="fieldRow" key={index}>
                  <TextField className={'grammes-format-'+index} label={'Quantité '+format+' (g)'} variant="outlined" type="number" onChange={(e) => setGrammesItem(parseInt(e.target.value),index)} value={grammes[index]}/>
                </div>
                ))}
                </>
                ) : null }
              </div>
              ) : null }

              <div className="fieldRow">
                <TextField required label="Date de récolte" variant="outlined" type="date" onChange={(e) => setHarvestdate(e.target.value)} value={harvestdate}/>
              </div>

            </form>
          </div>

          <div className="modal-actions">
            <Grid container spacing={2} justify="center">
              <Grid item xs={6}>
                { harvest ? <Button variant="contained" color="primary" onClick={doDelete}><DeleteIcon /></Button > : null }
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" fullWidth={true} onClick={save}>Enregistrer</Button>
              </Grid>
            </Grid>
          </div>


      </div>
    </Modal>
  );
  
};